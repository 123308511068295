<form *ngIf="metadataPendingDay" id="step_2_container" [formGroup]="form">
    <h2 style="grid-column: span 2;" i18n>Files uploaded on {{this.metadataPendingDay.date | date:'fullDate'}}</h2>
    <div class="scroll" style="grid-row: span 3;">
        <mat-selection-list formControlName="files">
            <mat-list-option *ngFor="let document of metadataPendingDay.files" [value]="document">
                <div class="document-row">
                    <app-doc-thumbnail id="{{document.id}}" [openInNewTab]="true"></app-doc-thumbnail>
                    <div class="document-row-text">
                        {{document.created | date:'MM/dd/yyyy'}} - Starting at {{document.created | date:'shortTime'}}
                    </div>
                </div>
            </mat-list-option>
        </mat-selection-list>
    </div>
    <div class="scroll" style="width: 35dvw;">
        <div>* indicates a required field.</div>
        <app-review-metadata-controls2 formGroupName="metadata"></app-review-metadata-controls2>
    </div>
    <div class="buttons-container">
        <button mat-button class="smx-secondary-button select-all" (click)="selectAllClicked()" i18n>
            Select All
        </button>
        <button mat-button class="smx-secondary-button ml-1 deselect-all"
                (click)="deselectAllClicked()"
                i18n>
            Select None
        </button>

        <button mat-button [disabled]="isSubmittingMetaData" class="smx-primary-button ml-1 review-selected"
                style="grid-column: -1;"
                (click)="reviewSelectedClicked()"
                i18n>
            <div *ngIf="isSubmittingMetaData === false">
                <span>Complete</span>
            </div>
            <div class="saving-button" *ngIf="isSubmittingMetaData === true">
                <mat-spinner color="accent" diameter=30></mat-spinner>
                <span>Saving...</span>
            </div>
        </button>
    </div>
</form>
