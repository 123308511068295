import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { IDeviceCameraViewModel, IDeviceViewModel } from '../live.component';

export interface DialogData {
    deviceVM: IDeviceViewModel;
}


@Component({
    selector: 'app-live-camera-select-dialog',
    standalone: false,
    templateUrl: './live-camera-select-dialog.component.html',
    styleUrl: './live-camera-select-dialog.component.css',
})
export class LiveCameraSelectDialogComponent {

    onlineCameras: IDeviceCameraViewModel[] = [];
    selectedCameras: IDeviceCameraViewModel[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public dialogRef: MatDialogRef<LiveCameraSelectDialogComponent>,
    ) {
    }

    ngOnInit() {
        this.onlineCameras = this.data.deviceVM.device.cameras.filter(x => x.online);
    }

    completeSelection() {
        this.dialogRef.close(this.selectedCameras);
    }
}
