<h2 mat-dialog-title>Request Deletion</h2>
<mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field subscriptSizing="dynamic" appearance="outline">
            <mat-label>Reason for deletion</mat-label>
            <input matInput formControlName="deleteRequestReason">
        </mat-form-field>

        <mat-dialog-actions>
            <button mat-button class="smx-primary-button ml-2" type="submit">Submit</button>
            <button [disabled]="isSubmittingDeletionRequest" mat-button class="smx-secondary-button ml-2" type="button" (click)="onCancel()">Cancel</button>
        </mat-dialog-actions>
    </form>
</mat-dialog-content>
