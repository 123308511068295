import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { DocumentTaskService } from 'src/app/services/api2';
import { FormControl, FormGroup } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'app-request-delete-dialog',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatDialogModule,
        MatInputModule,
        MatFormFieldModule
    ],
    templateUrl: './request-delete-dialog.component.html',
    styleUrl: './request-delete-dialog.component.css'
})
export class RequestDeleteDialogComponent {
    isSubmittingDeletionRequest: boolean;

    form = new FormGroup({
        deleteRequestReason: new FormControl('')
    });

    constructor(
        private dialogRef: MatDialogRef<RequestDeleteDialogComponent>,
        private documentTaskService: DocumentTaskService,
        private toastr: SnackbarService,
        @Inject(MAT_DIALOG_DATA) public documentId: string
    ) { }

    ngOnInit(): void {
    }

    onCancel() {
        this.dialogRef.close(false);
    }

    onSubmit() {
        this.isSubmittingDeletionRequest = true;

        this.documentTaskService.apiApiDocumentTaskRequestDeletePost(this.documentId, this.form.value.deleteRequestReason).subscribe((data) => {
            this.toastr.success('Deletion request successfully submitted.')
            this.isSubmittingDeletionRequest = false;
            this.dialogRef.close(true);
        },
            (err) => {
                this.toastr.error('An error occured while requesting deletion.')
                this.isSubmittingDeletionRequest = false;
            });

        // this.apiService.share_Post(this.data.documentId, 0, shareToSave).subscribe(
        //     (data) => {
        //         if (data.email.includes('@')) {
        //             data.displayName = data.email; //Overwrite display name with email for shares as they are added, see #2492
        //         } else {
        //             data.displayName = this.shareSelect.associatedUserEmail; //if shared with existing user, data.email is just display name
        //         }
        //         const foundShare = this.shares.find(
        //             (x) => x.email === data.email
        //         );
        //         if (foundShare === undefined) {
        //             this.shares.push(data);
        //             this.toastr.success('New share created.');
        //         } else {
        //             this.toastr.success(
        //                 `The existing share to ${foundShare.email} was updated.`
        //             );
        //             this.shares[this.shares.indexOf(foundShare)] = data;
        //         }
        //         this.clearShareInputs();
        //         this.shareButtonAllowed();

        //         this.dialogRef.close();
        //         this.isSubmittingDeletionRequest = false;
        //     },
        //     (err) => {
        //         if (
        //             err.response ===
        //             'You cannot share a document with yourself.'
        //         ) {
        //             this.toastr.error(err.response);
        //         } else {
        //             this.toastr.error(
        //                 'An error occurred while sharing the document.'
        //             );
        //         }

        //         this.isSubmittingDeletionRequest = false;
        //     }
        // );
    }
}
