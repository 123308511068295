<h2 mat-dialog-title>Approve Deletion Request?</h2>
<mat-dialog-content>

        <div class="row">
            <div class="col">

                <div class="mt-3">
                    <div i18n>

                        <div class="row">
                            <div class="col-md-4">
                                <label><b>Requested By</b></label>
                                <p>{{data.deletionRequest.requestorName}}</p>
                            </div>
                        </div>

                        <label><b>Reason</b></label>
                        <p>{{data.deletionRequest.reason}}</p>
                    </div>
                </div>
            </div>

        </div>
        <mat-dialog-actions>
            <button mat-button class="smx-primary-button mr-2"
                    (click)="approveDeleteRequest()" i18n>
                Yes,
                Approve
            </button>

            <button mat-button class="smx-critical-button"
                    (click)="denyDeleteRequest()" i18n>
                No,
                Deny
            </button>
        </mat-dialog-actions>
</mat-dialog-content>
