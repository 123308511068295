<div>
    <h2 i18n>Users - Bulk Create</h2>
    <hr />
    <div class="panel panel-default">
        <div class="panel-body">
            <p i18n>Create a csv file following the sample format below. Fill out as much as you can, but email and username are required. The username you enter will attempt to be used, but if it is invalid one will be created in place of it.</p>
            <a mat-button class="mb-2 smx-secondary-button" href="/static_downloads/sample_bulk_create.csv" target="_blank" i18n>Download Sample</a>
            <form class="upload-form">
                <div class="form-group">
                    <button [disabled]="uploading === true" class="smx-secondary-button">Browse...</button>
                    <input [disabled]="uploading === true" class="form-control" #file type="file" (change)="uploadFileChanged($event)" value="Select CSV File" accept=".csv" i18n-value />
                    <span>{{selectedFile}}</span>
                </div>
                <div class="upload-button">
                    <input mat-button class="mt-2 smx-primary-button" type="submit" value="Upload" (click)="uploadPost(file.files)" [disabled]="!content || uploading===true" />
                    <span *ngIf="uploading===true">Upload in progress. Please don't select any buttons or keys until the process is complete.</span>
                </div>
            </form>
        </div>
    </div>

    <div *ngIf="uploading===true">
        <mat-spinner class="mt-2" color="accent"></mat-spinner>
    </div>

    <div class="mt-2" *ngIf="response">
        <div class="alert alert-success" role="alert" *ngIf="response.Succeeded > 0">
            <strong>{{response.succeeded}}</strong> {{'Records Succeeded'}}
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="response.Errors !== undefined && response.Errors.length > 0">
            <strong>{{response.Errors.length}}</strong> {{'Records Failed'}}
        </div>
        <table class="table table-responsive" *ngIf="response.Errors !== undefined && response.Errors.length > 0">
            <thead>
                <tr>
                    <th>{{'Line Number'}}</th>
                    <th>{{'Record'}}</th>
                    <th>{{'Error'}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let error of response.Errors">
                    <td>{{error.LineNumber}}</td>
                    <td>{{error.RecordString}}</td>
                    <td>{{error.ExceptionInfo}}</td>
                </tr>

            </tbody>
        </table>
    </div>

</div>
