
<div class="outer-container">
    <div class="d-flex justify-content-between">
        <h2 i18n>RFID Assignment</h2>
    </div>

    <div class="inner-container">
        <div class="row">
            <div class="col-lg-8">
                <div class="card mt-3">
                    <div class="card-header">
                        <h3 class="card-title" i18n>What is RFID Assignment?</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">

                        <div class="col-sm-9">
                            <p i18n>
                                Pro-Vision provides RFID codes for BODYCAM 4. These RFID codes are assigned to a user so each user has a
                                unique RFID code. The user will scan their RFID with the BODYCAM 4
                                so the BODYCAM is assigned to that user while in use. All video will be 'tagged' with that user
                                information that will automatically be designated on SecuraMax once uploaded.
                            </p>
                        </div>

                        <div class="col-sm-3">
                            <p><strong i18n>RFID Example</strong></p>
                            <img src="assets/images/BC4-NFC product label.jpg" height="125" />
                        </div>
                    </div>

                    </div>
                </div>
                <div class="card mt-3">
                    <div class="card-header">
                        <h3 class="card-title" i18n>Assign new RFIDs to Users</h3>
                    </div>
                    <div class="card-body">
                        <div class="panel panel-body" style="text-align:center">
                            <p i18n>
                                Pro-Vision may have provided RFID cards. You can enter the RFIDs here. In the example above "B4000001"
                                is the RFID. To make entering RFIDs easier, your RFIDs should be in sequential order.
                                Enter the first RFID in your roll, then enter the number of RFIDs remaining for the rest to be populated
                                for you.
                            </p>
                        </div>

                        <div class="mb-3">
                            <mat-form-field class="mr-2" style="width:200px" hintLabel="Format: B4NNNNNNN">
                              <mat-label i18n>RFID</mat-label>
                              <input id="rfidInput" #rfidInputValid="ngModel" type="text" matInput
                                placeholder="Must be 8 characters" [(ngModel)]="rfidInput" minlength="8" maxlength="8"
                                pattern="B4\d{6}" name="rfid" (change)="CheckForRFIDOverwrite()" i18n-placeholder/>
                                <mat-error *ngIf="rfidInputValid.errors?.maxlength || rfidInputValid.errors?.minlength" i18n>RFID must be exactly 8 characters.</mat-error>
                                <mat-error *ngIf="rfidInputValid.errors?.pattern" i18n>RFID must match the pattern: B4NNNNNNN.</mat-error>
                            </mat-form-field>
                            <mat-form-field class="mr-2">
                                <mat-label i18n>Total New RFIDs</mat-label>
                                <input #numberOfRFIDTagsValid="ngModel" id="numberOfRFIDTags" type="number" matInput min="1"
                                  placeholder="Enter the number of RFID tags" [(ngModel)]="numberOfRFIDTags" i18n-placeholder/>
                            </mat-form-field>
                            <button mat-button class="smx-primary-button" [disabled]="!(rfidInputValid.valid && !rfidOverwrite && numberOfRFIDTagsValid.valid && rfidInput.length > 0)"
                               (click)="AddMultipleClicked()" i18n>Add {{numberOfRFIDTags}} Tag(s)</button>
                            <div class="mt-4" style="color:red;" *ngIf="rfidOverwrite" i18n>Your selection contains an assigned RFID tag, please change selection or remove assignnment below.</div>
                        </div>

                        <div *ngIf="RFIDUserList.length > 0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th i18n>RFID</th>
                                        <th i18n>Associated User</th>
                                        <th>
                                            <button mat-raised-button color="primary" (click)="SaveRFIDs()" i18n>Save</button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let rfidItem of RFIDUserList">
                                        <td>
                                            {{rfidItem.rFID}}
                                        </td>
                                        <td>
                                            <app-user-select [(userId)]="rfidItem.associatedUserId"
                                                [(associatedUserName)]="rfidItem.displayName" [includeCurrentUser]="true">
                                            </app-user-select>
                                        </td>
                                        <td>
                                            <button mat-raised-button (click)="RemoveRFIDItem(rfidItem)" i18n>Remove</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="card-header">
                      <h3 class="card-title" i18n>Current RFIDs Assigned to Users</h3>
                    </div>

                    <div class="card-body">
                        <table *ngIf="CurrentRFIDAssignmentsList.length > 0" class="table">
                            <thead>
                                <tr>
                                    <th i18n>RFID</th>
                                    <th i18n>Associated User</th>
                                    <th i18n>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let rfidItem of CurrentRFIDAssignmentsList; let i = index">
                                    <td>{{rfidItem.rFID}}</td>
                                    <td *ngIf="rfidEditArray[i]">
                                        <app-user-select [(userId)]="rfidItem.key" [(associatedUserName)]="rfidItem.displayName"
                                            [includeCurrentUser]="true"></app-user-select>
                                    </td>
                                    <td *ngIf="!rfidEditArray[i]">
                                        {{rfidItem.displayName}}
                                    </td>
                                    <td>
                                        <button mat-button class="smx-secondary-button mr-2 mb-2" *ngIf="!rfidEditArray[i]"
                                            (click)="rfidEditArray[i] = true; tempKey = rfidItem.key; tempName = rfidItem.displayName" i18n>
                                            Edit Assignment
                                        </button>
                                        <button mat-button class="smx-critical-button mb-2" *ngIf="!rfidEditArray[i]" (click)="DeleteAssignedRFID(rfidItem)" i18n>
                                            Delete Assignment
                                        </button>
                                        <button mat-button class="smx-critical-button mr-2 mb-2" *ngIf="rfidEditArray[i]"
                                            (click)="rfidEditArray[i] = false; rfidItem.key = tempKey; rfidItem.displayName = tempName; resetDefaultUser = !resetDefaultUser" i18n>
                                            Cancel Edit
                                        </button>
                                        <button mat-button class="smx-primary-button mb-2" *ngIf="rfidEditArray[i]"
                                            (click)="rfidEditArray[i] = SaveEditRFID(rfidItem)" i18n>
                                            Save Assignment
                                        </button>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
