import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../../services/title.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SecuraMaxApiService, CsvImportResponse, FileParameter } from '../../../services/api/securamaxapi.service';
import { ApiService } from 'src/app/services/api.service';
import { HttpClient } from '@angular/common/http';

export class CsvError {
    LineNumber: number;
    RecordString: string;
}
export class BulkCreateUsersResponse {
    Succeeded: number;
    Errors: Array<CsvError>;
};

@Component({
    selector: 'app-users-bulk-create',
    templateUrl: './users-bulk-create.component.html',
    styleUrls: ['./users-bulk-create.component.css']
})
export class UsersBulkCreateComponent implements OnInit {
    response: any;
    response2: any;
    content: FormData;
    uploading: boolean = false;
    file: FileParameter;
    selectedFile: string = "No file selected."

    constructor(private titleService: TitleService,
        private securamaxApiService: SecuraMaxApiService,
        private apiService: ApiService,
        private toastr: SnackbarService,
        private http: HttpClient) { }

    ngOnInit(): void {
        this.titleService.setTitle('Bulk Upload Users');
    }

    uploadPost(files) {
        if (files.length === 0) return;

        var that = this;
        this.uploading = true;

        let fileToUpload = <File>files[0];

        const formData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);

        this.apiService.bulkCreateUsers(formData).subscribe((event: any) => {
            console.log(event);
            if (event.Errors.length == 0) {
                this.toastr.success('Users imported successfully');
            }
            else {
                this.toastr.error('There were errors importing users');
            }
            that.response = event;

            this.uploading = false;
        });
    }

    uploadFileChanged(event) {
        if (!event.target.files.length) {
            return null;
        }

        this.selectedFile = event.target.files[0].name

        this.resetForm();

        var that = this;

        that.content = new FormData();
        that.content.append('file', event.target.files[0], event.target.files[0].name);
    }

    resetForm() {
        this.response = null;
        this.content = null;
        this.uploading = false;
    }
}
