import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProfileComponent} from './components/profile/profile.component';
import {HomeComponent} from './components/home/home.component';
import {CasesComponent} from './components/cases/cases/cases.component';
import {MetadataComponent} from './components/metadata/metadata.component';
import {ShellComponent} from './components/shell/shell.component';
import {SoftwareComponent} from './components/software/software.component';
import {HelpComponent} from './components/help/help.component';
import {UploadComponent} from './components/upload/upload.component';
import {SettingsComponent} from './components/settings/settings.component';
import {ApiSettingsComponent} from './components/settings/api-settings/api-settings.component';
import {UploadStationsComponent} from './components/upload-stations/upload-stations.component';
import {CategoriesComponent} from './components/categories/categories.component';
import {Bc300configsComponent} from './components/devices/bc300configs/bc300configs.component';
import {DevicesListComponent} from './components/devices/devices-list/devices-list.component';
import {DevicesNavigationComponent} from './components/devices/devices-navigation/devices-navigation.component';
import {UsersComponent} from './components/users/users.component';
import {GroupsComponent} from './components/users/groups/groups.component';
import {GroupDetailComponent} from './components/users/group-detail/group-detail.component';
import {DevicesFileTransferComponent} from './components/devices/devices-file-transfer/devices-file-transfer.component';
import {DevicesFileTransferComponent2} from './components/devices/devices-file-transfer2/devices-file-transfer2.component';
import {Bc300configsAssignComponent} from './components/devices/bc300configs-assign/bc300configs-assign.component';
import {Bc300configsCreateComponent} from './components/devices/bc300configs-create/bc300configs-create.component';
import {Bc300configsDeleteComponent} from './components/devices/bc300configs-delete/bc300configs-delete.component';
import {Bc300configsEditComponent} from './components/devices/bc300configs-edit/bc300configs-edit.component';
import {Bc4configsAssignComponent} from './components/devices/bc4configs-assign/bc4configs-assign.component';
import {Bc4configsCreateComponent} from './components/devices/bc4configs-create/bc4configs-create.component';
import {Bc4configsDeleteComponent} from './components/devices/bc4configs-delete/bc4configs-delete.component';
import {Bc4configsEditComponent} from './components/devices/bc4configs-edit/bc4configs-edit.component';
import {Bc4configsComponent} from './components/devices/bc4configs/bc4configs.component';
import {DevicesBc4SetupComponent} from './components/devices/devices-bc4-setup/devices-bc4-setup.component';
import {TagsComponent} from './components/tags/tags.component';
import {DevicesDetailsComponent} from './components/devices/devices-details/devices-details.component';
import {PendingdeletionComponent} from './components/reports/pendingdeletion/pendingdeletion.component';
import {ReportsComponent} from './components/reports/reports.component';
import {AuditUserComponent} from './components/reports/audit-user/audit-user.component';
import {
    AwaitingDeleteApprovalComponent
} from './components/reports/awaiting-delete-approval/awaiting-delete-approval.component';
import {DeviceUsageComponent} from './components/reports/device-usage/device-usage.component';
import {UsageHistoryComponent} from './components/reports/usage-history/usage-history.component';
import {
    DocumentsSearchListComponent
} from './components/documents/documents-search-list/documents-search-list.component';
import {DocumentDetailsComponent} from './components/documents/document-details/document-details.component';
import {DocumentDetails2Component} from './components/documents/document-details2/document-details2.component';
import {UsersListComponent} from './components/users/users-list/users-list.component';
import {MemberRedeemComponent} from './components/members/member-redeem/member-redeem.component';
import {UserInviteComponent} from './components/users/user-invite/user-invite.component';
import {UsersEditComponent} from './components/users/users-edit/users-edit.component';
import {UsersRfidComponent} from './components/users/users-rfid/users-rfid.component';
import {AnnotateComponent} from './components/annotate/annotate/annotate.component';
import {RedactionCreateComponent} from './components/redaction/redaction-create/redaction-create.component';
import {RedactionCreateV2Component} from './components/redaction/redaction-createV2/redaction-createV2.component';
import {
    RedactionAdvancedCreateComponent
} from './components/redaction/redaction-advanced-create/redaction-advanced-create.component';
import {
    DocumentsSearchTimelineComponent
} from './components/documents/documents-search-timeline/documents-search-timeline.component';
import {DocumentsSearchComponent} from './components/documents/documents-search/documents-search.component';
import {ReviewByScheduleComponent} from './components/review/review-by-time/review-by-time.component';
import {
    ReviewByScheduleListComponent
} from './components/review/review-by-time-list/review-by-time-list.component';
import {
    ReviewByScheduleDetailsComponent
} from './components/review/review-by-time-details/review-by-time-details.component';
import {TransferDevicesListComponent} from './components/devices/transfer-devices-list/transfer-devices-list.component';
import {CreateTransferComponent} from './components/devices/create-transfer/create-transfer.component';
import {
    DevicesPendingTransferComponent
} from './components/devices/devices-pending-transfer/devices-pending-transfer.component';
import {TransferComponent} from './components/devices/transfer/transfer.component';
import {ForbiddenComponent} from './components/error/forbidden/forbidden.component';
import {SecuramaxPermissionGuard} from './shared/guards/securamax-permission-guard.guard';
import {RedactionDetailsComponent} from './components/redaction/redaction-details/redaction-details.component';
import {ShareVerifyComponent} from './components/share/share-verify/share-verify.component';
import {
    DocumentStorageUsageComponent
} from './components/reports/document-storage-usage/document-storage-usage.component';
import {UsersBulkCreateComponent} from './components/users/users-bulk-create/users-bulk-create.component';
import { LiveComponent } from './components/live/live.component';
import { LiveAuthComponent } from './components/live/live-auth.component';
import {Annotate2Component} from "./components/annotate/annotate2/annotate2.component";
import {Annotate2Step1Component} from "./components/annotate/annotate2-step1/annotate2-step1.component";
import {Annotate2Step2Component} from "./components/annotate/annotate2-step2/annotate2-step2.component";
import {CasesCreate2Component} from "./components/cases/cases-create2/cases-create2.component";
import {DocumentsSearch2Component} from "./components/documents/documents-search2/documents-search2.component";
import {CasesDetails2Component} from "./components/cases/cases-details2/cases-details2.component";
import {CasesEdit2Component} from "./components/cases/cases-edit2/cases-edit2.component";
import { DevicesBc4Setup2Component } from './components/devices/devices-bc4-setup2/devices-bc4-setup2.component';

import { featureFlagGuard } from './util/feature-flag.guard'

const routes: Routes = [
    {
        path: 'profile',
        component: ProfileComponent
    },
    {
        // Needed for hash routing
        path: 'error',
        component: HomeComponent
    },
    {
        // Needed for hash routing
        path: 'state',
        component: HomeComponent
    },
    {
        // Needed for hash routing
        path: 'code',
        component: HomeComponent
    },
    {
        path: 'members/redeem',
        component: MemberRedeemComponent,
    },
    {
        path: 'admin/apisettings',
        component: ApiSettingsComponent,
        canActivate: [SecuramaxPermissionGuard],
        data: {'permission': 'manage_site_settings'}
    },
    {
        path: 'admin/settings',
        component: SettingsComponent,
        canActivate: [SecuramaxPermissionGuard],
        data: {'permission': 'manage_site_settings'}
    },
    {
        path: 'home',
        component: HomeComponent,
        children: [
            {
                path: '',
                component: ShellComponent
            }
        ]
    },
    {
        path: 'live',
        component: LiveComponent,
        canActivate: [SecuramaxPermissionGuard]
    },
    {
        path: 'live-auth',
        component: LiveAuthComponent
    },
    {
        path: '',
        component: HomeComponent,
    },
    {
        path: 'help',
        component: HelpComponent,
    },
    {
        path: 'software',
        component: SoftwareComponent,
    },
    {
        path: 'upload',
        component: UploadComponent,
        canActivate: [SecuramaxPermissionGuard],
        data: {'permission': 'upload_directly'}
    },
    {
        path: 'metadata',
        component: MetadataComponent
    },
    {
        path: 'devices',
        children: [
            {
                path: 'transfer',
                component: TransferDevicesListComponent
            },
            {
                path: 'transfer/:id/request',
                component: CreateTransferComponent
            }
        ]
    },
    {
        path: 'annotate',
        component: AnnotateComponent
    },
    {
        path: 'annotate2',
        component: Annotate2Component,
        children: [
            {
                path: 'step1',
                component: Annotate2Step1Component
            },
            {
                path: 'step2',
                component: Annotate2Step2Component
            },
        ]
    },
    {
        path: 'review/autoreviewrules/new',
        component: ReviewByScheduleComponent
    },
    {
        path: 'review/autoreviewrules/list',
        component: ReviewByScheduleListComponent
    },
    {
        path: 'review/autoreviewrules/:id/details',
        component: ReviewByScheduleDetailsComponent
    },
    {
        path: 'cases',
        component: CasesComponent
    },
    {
        path: 'cases/create',
        component: CasesCreate2Component
    },
    {
        path: 'cases/:id/details',
        component: CasesDetails2Component
    },
    {
        path: 'cases/:id/details2',
        component: CasesDetails2Component
    },
    {
        path: 'cases/:id/edit',
        component: CasesEdit2Component
    },
    {
        path: 'search1',
        component: DocumentsSearchComponent,
        children: [
            {
                path: 'documents',
                component: DocumentsSearchListComponent
            },
            {
                path: 'timeline',
                component: DocumentsSearchTimelineComponent
            }
        ]
    },
    {
        path: 'search',
        component: DocumentsSearch2Component,
    },
    {
        path: 'search/documents',
        component: DocumentsSearch2Component,
    },
    {
        path: 'search/timeline',
        component: DocumentsSearch2Component,
    },
    {
        path: 'share/:id/:verificationKey',
        // todo reimplement share component after release
        component: ShareVerifyComponent
    },
    {
        path: 'documents/:id',
        canMatch: [featureFlagGuard("DocumentDetailsV2")],
        component: DocumentDetails2Component,
        //canActivate: [MsalGuard]
    },
    {
        path: 'documents/:id',
        component: DocumentDetailsComponent,
        //canActivate: [MsalGuard]
    },
    {
        path: 'documents1/:id',
        component: DocumentDetailsComponent,
        //canActivate: [MsalGuard]
    },
    {
        path: 'documents2/:id',
        component: DocumentDetails2Component,
        //canActivate: [MsalGuard]
    },
    {
        path: 'documents/:id/redactions/advanced/:redactionId',
        component: RedactionAdvancedCreateComponent,
    },
    {
        path: 'documents/:id/redactions/advanced',
        component: RedactionAdvancedCreateComponent,
    },
    {
        path: 'documents/:id/redactions/create',
        canMatch: [featureFlagGuard("RedactionsV2")],
        component: RedactionCreateV2Component,
    },
    {
        path: 'documents/:id/redactions/create',
        component: RedactionCreateComponent,
    },
    {
        path: 'documents/:id/redactions/:redactionId',
        component: RedactionDetailsComponent,
    },

    {
        path: 'transfer',
        component: TransferComponent,
        children: [
            {
                path: 'list',
                component: TransferDevicesListComponent
            },
            {
                path: 'pending',
                component: DevicesPendingTransferComponent
            },
            {
                path: ':id/request',
                component: CreateTransferComponent
            }
        ]
    },
    {
        path: 'admin',
        children: [
            {
                path: 'users',
                component: UsersComponent,
                children: [
                    {
                        path: 'list',
                        component: UsersListComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_users'}
                    },
                    {
                        path: 'bulkcreate',
                        component: UsersBulkCreateComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_users'}
                    },
                    {
                        path: 'edit/:id',
                        component: UsersEditComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_users'}
                    },
                    {
                        path: 'invite',
                        component: UserInviteComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_users'}
                    },
                    {
                        path: 'groups',
                        component: GroupsComponent,
                        children: [
                            {
                                path: 'create',
                                component: GroupDetailComponent,
                                canActivate: [SecuramaxPermissionGuard],
                                data: {'permission': 'manage_groups'}
                            },
                            {
                                path: ':id',
                                component: GroupDetailComponent,
                                canActivate: [SecuramaxPermissionGuard],
                                data: {'permission': 'manage_groups'}
                            }
                        ],
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_groups'}
                    },
                    {
                        path: 'rfid',
                        component: UsersRfidComponent
                    }
                ]
            },
            {
                path: 'devices',
                component: DevicesNavigationComponent,
                children: [
                    {
                        path: 'list',
                        component: DevicesListComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_devices'}
                        //canActivate: [MsalGuard]
                    },
                    {
                        path: 'approve',
                        component: DevicesFileTransferComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_devices'}
                        //canActivate: [MsalGuard]
                    },
                    {
                        path: 'approve2',
                        component: DevicesFileTransferComponent2,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_devices'}
                        //canActivate: [MsalGuard]
                    },
                    {
                        path: 'bc4setup',
                        canMatch: [featureFlagGuard("DeviceRegistrationV2")],
                        component: DevicesBc4Setup2Component,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_devices'}
                        //canActivate: [MsalGuard]
                    },
                    {
                        path: 'bc4setup',
                        component: DevicesBc4SetupComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_devices'}
                        //canActivate: [MsalGuard]
                    },
                    {
                        path: ':id/details',
                        component: DevicesDetailsComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_devices'}
                        //canActivate: [MsalGuard]
                    },
                    {
                        path: 'bc300configs',
                        component: Bc300configsComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_devices'}
                    },
                    {
                        path: 'bc300configs/assign',
                        component: Bc300configsAssignComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_devices'}
                    },
                    {
                        path: 'bc300configs/create',
                        component: Bc300configsCreateComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_devices'}
                    },
                    {
                        path: 'bc300configs/:id/edit',
                        component: Bc300configsEditComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_devices'}
                    },
                    {
                        path: 'bc300configs/:id/delete',
                        component: Bc300configsDeleteComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_devices'}
                    },
                    {
                        path: 'bc4configs',
                        component: Bc4configsComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_devices'}
                    },
                    {
                        path: 'bc4configs/assign',
                        component: Bc4configsAssignComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_devices'}
                    },
                    {
                        path: 'bc4configs/create',
                        component: Bc4configsCreateComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_devices'}
                    },
                    {
                        path: 'bc4configs/:id/edit',
                        component: Bc4configsEditComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_devices'}
                    },
                    {
                        path: 'bc4configs/:id/delete',
                        component: Bc4configsDeleteComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_devices'}
                    },
                    {
                        path: '',
                        redirectTo: 'list',
                        pathMatch: 'full'
                        //canActivate: [MsalGuard]
                    }
                ],
                canActivate: [SecuramaxPermissionGuard],
                data: {'permission': 'manage_devices'}
            },
            {
                path: 'uploadstations',
                component: UploadStationsComponent,
                canActivate: [SecuramaxPermissionGuard],
                data: {'permission': 'manage_upload_stations'}
            },
            {
                path: 'categories',
                component: CategoriesComponent,
                canActivate: [SecuramaxPermissionGuard],
                data: {'permission': 'manage_categories'}
            },
            {
                path: 'tags',
                component: TagsComponent,
                canActivate: [SecuramaxPermissionGuard],
                data: {'permission': 'manage_tags'}
            },
            {
                path: 'reports',
                component: ReportsComponent,
                children: [
                    {
                        path: 'pendingdeletion',
                        component: PendingdeletionComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'view_report_pending_deletion'}
                    },
                    {
                        path: 'auditUser',
                        component: AuditUserComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'manage_users'}
                    },
                    {
                        path: 'awaitingDeleteApproval',
                        component: AwaitingDeleteApprovalComponent
                    },
                    {
                        path: 'deviceusage',
                        component: DeviceUsageComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'view_report_device_usage'}
                    },
                    {
                        path: 'usagehistory',
                        component: UsageHistoryComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'view_report_device_usage'}
                    },
                    {
                        path: 'documentStorageUsage',
                        component: DocumentStorageUsageComponent,
                        canActivate: [SecuramaxPermissionGuard],
                        data: {'permission': 'view_report_device_usage'}
                    }
                ]
            },
            {
                path: '**',
                redirectTo: '/',
                pathMatch: 'full'
            }
        ]
    },
    {
        path: 'forbidden',
        component: ForbiddenComponent,
    }

];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: true,
        // Don't perform initial navigation in iframes
        initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled'
    })],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
