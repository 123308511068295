import { Injectable } from '@angular/core';
import { RuntimeModel } from '../api2';
import { AppService as ApiAppService } from 'src/app/services/api2';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    runtime: RuntimeModel;

    constructor(private apiAppService: ApiAppService) {}

    getRuntime() {
        return new Promise<RuntimeModel>((resolve, reject) => {
            if (this.runtime != null) {
                resolve(this.runtime);
            } else {
                this.apiAppService.apiApiAppRuntimeGet().subscribe(r => {
                    this.runtime = r;
                    resolve(r);
                }, x => reject(x));
            }
        });
    }
}
