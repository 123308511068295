import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    CasesListViewModel,
    CategoryViewModel,
    DocumentCaseItemViewModel,
    DocumentDetailsViewModel,
    DocumentMetadataViewModel,
    DocumentTagViewModel,
    SecuraMaxApiService,
    ShareViewModel,
    TagTypeViewModel,
    TagViewModel,
} from 'src/app/services/api/securamaxapi.service';
import { UserSelectModel } from 'src/app/models/user-select.model';
import { TagSelectViewModel } from 'src/app/models/tag-select-view-model.model';
import { ApiService } from 'src/app/services/api.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateCaseDialog2Component } from './create-case/create-case-dialog2/create-case-dialog2.component';
import * as moment from 'moment';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ShareModifyDialog2Component } from './share-modify-dialog2/share-modify-dialog2.component';
import { Location, ViewportScroller } from '@angular/common';
import { TitleService } from 'src/app/services/title.service';
import { Observable } from 'rxjs-compat/Observable';
import { UserSelectClearService } from 'src/app/services/user/user-select-clear.service';
import { UserService } from 'src/app//services/user/user.service';
import { documentStatus } from 'src/app/shared/helpers/document-status-const';
import {
    IShareCreateDialog2ComponentDialogDataViewModel,
    ShareCreateDialog2Component,
} from './share-create-dialog2/share-create-dialog2.component';
import {
    IMetadataModifyDialogComponentDialogDataViewModel,
    MetadataModifyDialogComponent,
} from './metadata-modify-dialog/metadata-modify-dialog.component';
import { DocumentDetailsDialogComponent } from './document-details-dialog/document-details-dialog.component';
import { RequestDeleteDialogComponent } from './request-delete-dialog/request-delete-dialog.component';
import { ReviewDeleteRequestDialogComponent } from './review-delete-request-dialog/review-delete-request-dialog.component';

export enum shareUserType {
    exisitingUser = 0,
    newUser = 1,
}

export interface reviewDeleteRequestDialogResult {
    isPending: boolean;
    removeDeletionRequest: boolean;
}

@Component({
    selector: 'app-document-details2',
    templateUrl: './document-details2.component.html',
    styleUrls: ['./document-details2.component.scss'],
})
export class DocumentDetails2Component implements OnInit {
    isUnlimitedPlanUser: boolean = false;
    documentStatus: typeof documentStatus = documentStatus;
    loading: boolean = true;
    document: DocumentDetailsViewModel;
    requestedFromArchive: boolean = false;
    isRequestRetrieve: boolean = false;
    videoSrcUrl: string = null;
    imageSrcUrl: string = null;
    shareSelect: UserSelectModel = new UserSelectModel(); // Contains the user selected for sharing the video.
    shareUserType: shareUserType = 0;
    newShareUserInput: string = '';
    shareUserSelectName: string = 'shareUserSelect';
    shareMinDate: Date = new Date();
    shareDate: Date;
    shareTime: string;
    shareCanDownload: boolean = false;
    shareCanAddMetadata: boolean = false;
    shareCanReshare: boolean = false;
    shares: ShareViewModel[] = null;
    sharesIsLoading: boolean = true;
    shareSaveDisabled = true;
    isSavingShareChanges = false;

    isRequestDelete: boolean = false;
    requestDeleteSubmitClicked = false;
    deleteRequestReason: string;
    isReviewDeletionRequest: boolean;

    // Fields for editing metadata. We create separate vars so they aren't immediately changed in the 'Details' section.
    editMetaData: boolean = false;
    isSavingMetadata: boolean = false;
    titleToEdit: string = '';
    titleToEditError: string = null;
    descriptionToEdit: string = '';
    importantToEdit: boolean;
    ownerSelect: UserSelectModel = new UserSelectModel(); // Contains the user that selected when changing the owner.

    categoryList: CategoryViewModel[] = []; // Contains the list of all categories for tenant.
    selectedCategory: CategoryViewModel = new CategoryViewModel(); // Used for storing the category selected via the dropdown to be added to the doc.
    categoryEditList: CategoryViewModel[] = []; // List used when editing categories on the document.

    tagsList: TagViewModel[] = []; // Contains the list of all tags.
    selectedTag: TagViewModel = new TagViewModel(); // Used for storing the tag selected via the dropdown to be added to the doc..
    tagsEditList: TagSelectViewModel[] = []; // List of tags that are part of the document..

    caseListItem: CasesListViewModel = new CasesListViewModel(); // Used by app-case-select directive for adding/searching cases.
    caseEditList: CasesListViewModel[] = []; // List used when editing the cases on the document.

    emailRegEx = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    sharePanelOpen: boolean = false;
    showShare: boolean = false;
    showRedactions: boolean = false;
    showViewerLink: boolean = false;
    docInOpenCaseWithRetentionList: DocumentCaseItemViewModel[];

    isWindows: boolean = navigator.userAgent.indexOf('Win') != -1;

    daterangepicker_start: string;
    daterangepicker_end: string;

    constructor(
        private apiService: SecuraMaxApiService,
        private titleService: TitleService,
        private route: ActivatedRoute,
        private api: ApiService,
        private toastr: SnackbarService,
        public createCaseDialog: MatDialog,
        // private cancelShareDialog: MatDialog,
        private modifyShareDialog: MatDialog,
        private dialog: MatDialog,
        private location: Location,
        private viewPortScroller: ViewportScroller,
        private userSelectClearService: UserSelectClearService,
        private userService: UserService
    ) { }

    private isNullOrEmpty(item: string) {
        if (item && item.length > 0) {
            return true;
        }
        return false;
    }

    ngOnInit(): void {
        if (this.userService.isUnlimitedPlan) {
            this.isUnlimitedPlanUser = true;
        }

        this.route.paramMap.subscribe((parammap) => {
            this.loading = true;
            this.document = null;
            this.videoSrcUrl = null;
            this.imageSrcUrl = null;
            this.shares = [];
            let id = parammap.get('id');
            this.apiService
                .documents_Get(id)
                .toPromise()
                .then(
                    (data) => {
                        this.document = data;

                        this.showRedactions =
                            this.document.redactions.length > 0;
                        this.titleService.setTitle('Document Details');
                        this.docInOpenCaseWithRetentionList =
                            data.documentCaseItemList.filter(
                                (c) => c.status === 1 && c.retention > 0
                            );
                        if (this.document.versions?.length > 0) {
                            this.videoSrcUrl = this.document.versions.find(
                                (x) => x.videoFormat === 'mp4'
                            )?.url;
                            this.imageSrcUrl = this.document.versions.find(
                                (x) => x.videoFormat === 'image/jpeg'
                            )?.url;
                        } else if (
                            this.document.parentId != null &&
                            this.document.parentId.length > 0
                        ) {
                            //try to load download url and set to video source
                            this.api
                                .downloadDocument(this.document.id)
                                .toPromise()
                                .then((url) => {
                                    this.videoSrcUrl = url;
                                });
                        }

                        this.loading = false;
                    },
                    () => {
                        this.toastr.error(
                            'An error occurred while getting the document.'
                        );
                    }
                );

            this.apiService
                .share_GetAll(id)
                .toPromise()
                .then(
                    (data) => {
                        this.shares = data;
                        this.sharesIsLoading = false;
                    },
                    () => {
                        this.toastr.error(
                            'An error occurred while getting document shares.'
                        );
                        this.shares = [];
                        this.sharesIsLoading = false;
                    }
                );
        });
    }

    openModifyMetadataDialog() {
        const dialogData: IMetadataModifyDialogComponentDialogDataViewModel = {
            document: this.document,
        };

        const dialogRef = this.dialog.open(MetadataModifyDialogComponent, {
            data: dialogData,
            width: '50%',
            restoreFocus: false,
        });

        dialogRef
            .afterClosed()
            .toPromise()
            .then(() => {
                let id = this.route.snapshot.paramMap.get('id');
                this.apiService.documents_Get(id).subscribe((data) => {
                    this.document = data;
                });
            });
    }

    openCreateShareDialog() {
        const dialogData: IShareCreateDialog2ComponentDialogDataViewModel = {
            documentId: this.document.id,
        };

        const dialogRef = this.dialog.open(ShareCreateDialog2Component, {
            data: dialogData,
            width: '50%',
            restoreFocus: false,
        });
        dialogRef
            .afterClosed()
            .toPromise()
            .then(() => {
                let id = this.route.snapshot.paramMap.get('id');
                this.apiService
                    .share_GetAll(id)
                    .toPromise()
                    .then(
                        (data) => {
                            this.shares = data;
                            this.sharesIsLoading = false;
                        },
                        () => {
                            this.toastr.error(
                                'An error occurred while getting document shares.'
                            );
                            this.shares = [];
                            this.sharesIsLoading = false;
                        }
                    );
            });
    }

    // openCancelShareDialog(share: ShareViewModel) {
    //     console.log("cancel share")
    //     const dialogRef = this.cancelShareDialog.open(ConfirmDialogComponent, {
    //         maxWidth: '400px',
    //         data: {
    //             title: 'Are you sure?',
    //             message: `You are about to unshare this document with: ${share.displayName}`,
    //         },
    //     });

    //     dialogRef.afterClosed().subscribe((dialogResult) => {
    //         // if user pressed yes dialogResult will be true,
    //         // if he pressed no - it will be false
    //         if (dialogResult) {
    //             this.apiService.share_Cancel(share.id).subscribe(
    //                 () => {
    //                     this.toastr.success('Share cancelled.');
    //                     const index = this.shares.findIndex(
    //                         (x) => x.id == share.id
    //                     );
    //                     if (index != -1) this.shares.splice(index, 1);
    //                 },
    //                 (err) => {
    //                     this.toastr.error(
    //                         'An error occurred while cancelling the share.'
    //                     );
    //                 }
    //             );
    //         }
    //     });
    // }

    openAdditionalDetailsDialog() {
        console.log(this.document)
        this.dialog.open(DocumentDetailsDialogComponent, {
            data: {
                document: this.document,
                docInOpenCaseWithRetentionList:
                    this.docInOpenCaseWithRetentionList,
            },
            width: '50dvw',
        });
    }

    /**
     * Downloads the document.
     */
    downloadDocument() {
        this.api.downloadDocument(this.document.id).subscribe((url) => {
            window.location.href = url;
        });
    }

    streamDocument() {
        this.showViewerLink = true;

        this.api.downloadDocument(this.document.id).subscribe((url) => {
            window.location.href = url.replace('https://', 'pv-hdviewer://');
        });
    }

    getDownloadUrl(): Observable<string> {
        return this.api.downloadDocument(this.document.id);
    }

    /**
     * Downloads the audit report.
     */
    downloadAuditReport() {
        this.api.downloadDocumentAuditReport(this.document.id).subscribe(
            (x) => {
                this.toastr.success(
                    'Audit report downloaded. Check download location.'
                );
            },
            (err) => {
                this.toastr.error(
                    'An error occurred while downloading audit report'
                );
            }
        );
    }

    openRequestDeleteDialog() {
        const dialogRef = this.dialog.open(RequestDeleteDialogComponent, {
            data: this.document.id,
            width: '50%',
            restoreFocus: false
        });

        dialogRef
            .afterClosed()
            .subscribe((submitted: boolean) => {
                if (submitted) {
                    this.requestDeleteSubmitClicked = submitted;
                    // this.isReviewDeletionRequest = true;
                }
            });
    }

    // requestDeleteSubmit() {
    //     this.isRequestDelete = true;
    //     this.requestDeleteSubmitClicked = true;
    //     if (
    //         this.document.permissions.canDelete &&
    //         this.document.isDeletionLocked === false
    //     ) {
    //         this.apiService
    //             .documentTask_PostDeletionRequest(
    //                 this.document.id,
    //                 this.deleteRequestReason
    //             )
    //             .subscribe(
    //                 (x) => {
    //                     //this.$state.go('documents.details', { id: this.document.Id }, { reload: true });
    //                     //that.document.DeletionRequest = req;
    //                     this.toastr.success('Successfully requested delete.');
    //                     window.location.reload();
    //                     this.isRequestDelete = false;
    //                 },
    //                 (err) => {
    //                     this.toastr.error(
    //                         'An error occurred when requesting deletion.'
    //                     );
    //                     this.isRequestDelete = false;
    //                     this.requestDeleteSubmitClicked = false;
    //                 }
    //             );
    //     }
    // }

    openReviewDeleteRequestDialog() {
        const dialogRef = this.dialog.open(ReviewDeleteRequestDialogComponent, {
            data: { deletionRequest: this.document.deletionRequest, permssions: this.document.permissions },
            width: '50%',
            restoreFocus: false
        });

        dialogRef
            .afterClosed()
            .subscribe((result: reviewDeleteRequestDialogResult) => {
                this.document.isPendingDelete = result.isPending;
                if (result.removeDeletionRequest) {
                    delete this.document.deletionRequest
                    this.toastr.success('Successfully disapproved delete.');
                }
            });
    }

    denyDeleteRequest() {
        this.isReviewDeletionRequest = false;

        if (
            this.document.permissions.canApproveDelete &&
            this.document.isDeletionLocked === false
        ) {
            this.apiService
                .documentTask_PostApproveDeletionRequest(
                    this.document.deletionRequest.taskId,
                    false
                )
                .subscribe(
                    (x) => {
                        this.document.isPendingDelete = false;
                        delete this.document.deletionRequest;
                        this.toastr.success('Successfully disapproved delete.');
                    },
                    (err) => {
                        this.toastr.error(
                            'An error occurred while approving deletion.'
                        );
                    }
                );
        }
    }

    approveDeleteRequest() {
        this.isReviewDeletionRequest = false;
        this.document.isPendingDelete = true;
    }

    lockFromDeletion() {
        console.log("we are locking")
        this.apiService
            .documents_PutLockFromDeletion(this.document.id.toString(), true)
            .subscribe(
                () => {
                    console.log("We got the request back")
                    this.document.isDeletionLocked = true;
                    this.document.deletionDate = null;
                    this.isRequestDelete = false;
                    this.isReviewDeletionRequest = false;
                    this.toastr.success('Document is locked from deletion.');
                },
                (err) => {
                    this.toastr.error(
                        'Unable to lock at this time. Try again later.'
                    );
                }
            );
    }

    unlockFromDeletion() {
        this.apiService
            .documents_PutLockFromDeletion(this.document.id.toString(), false)
            .subscribe(
                (updatedDeletionDate) => {
                    this.document.isDeletionLocked = false;
                    this.document.deletionDate = updatedDeletionDate;
                    this.toastr.success('Document is unlocked from deletion.');
                },
                (err) => {
                    this.toastr.error(
                        'Unable to unlock at this time. Try again later.'
                    );
                }
            );
    }

    /**
     * Shows the edit form for modifying the documents metadata.
     * We copy the document information to the temp variables so the
     * data change isn't reflected in the 'details' section.
     */
    editMetaDataClick() {
        this.editMetaData = !this.editMetaData;
        this.titleToEditError = null;
        this.titleToEdit = this.document.title;
        this.descriptionToEdit = this.document.description;
        this.ownerSelect.associatedUser = this.document.ownerName;
        this.ownerSelect.associatedUserId = this.document.ownerId;
        this.categoryEditList = Object.assign([], this.document.categories);
        this.caseEditList = Object.assign(
            [],
            this.document.documentCaseItemList
        );
        this.importantToEdit = this.document.important;

        this.loadCategories().then(() => {
            this.viewPortScroller.scrollToAnchor('editMetaDataContainer');
        });
        this.loadTags();
        //this.scrollToEditMetadata();
    }

    /**
     * Loads the categories from the server. Only happens once on the first time
     * the 'Edit Metadata' button is clicked.
     */
    loadCategories() {
        return this.apiService
            .categories_GetAll()
            .toPromise()
            .then(
                (data) => {
                    this.categoryList = data;
                },
                (err) => {
                    this.toastr.error(
                        'An error occurred while loading categories.'
                    );
                }
            );
    }

    /**
     * Loads the tags from the server. Populates the 'tags' section of the
     * edit form with tags that exist on the document.
     */
    loadTags() {
        if (this.tagsList.length <= 0) {
            // Get all tags to show in the list. This will include tags that have
            // been deleted from the 'lookups' page but are still part of the document.
            this.apiService.tags_GetAll().subscribe(
                (data) => {
                    this.tagsList = data;

                    // Get each tag in the document and display it in the tags list.
                    // We pass the tag.id to be sent back to the server incase the value is updated.
                    // tag.value will display the value in the input field.
                    this.document.tags.forEach((tag) => {
                        var tagItem = this.tagsList.find(
                            (x) => x.id == tag.tagId
                        );
                        this.addTag(tagItem, tag.id, tag.value);
                    });
                    // Now only display active tags that haven't been deleted from the lookups page.
                    this.apiService.tags_GetAllActiveTags().subscribe(
                        (data) => {
                            this.tagsList = data;
                        },
                        (err) => {
                            this.toastr.error(
                                'An error occurred while loading tags.'
                            );
                        }
                    );
                },
                (err) => {
                    this.toastr.error('An error occurred while loading tags.');
                }
            );
        }
    }

    isShareExpired(share: ShareViewModel) {
        return share.expires < new Date();
    }

    /**
     * Adds a category to the document.
     * @param category Category to add to document.
     */
    addCategory(category) {
        const index = this.categoryEditList.findIndex(
            (x) => x.name == category.name
        );

        if (index == -1) {
            this.categoryEditList.push(category);
        }
    }

    /**
     * Removes a category from the document.
     * @param category Category to remove from document
     */
    removeCategory(category) {
        const index = this.categoryEditList.indexOf(category);

        if (index >= 0) {
            this.categoryEditList.splice(index, 1);
        }
    }

    /**
     * Adds a case to the document.
     */
    addCase() {
        const index = this.caseEditList.findIndex(
            (x) => x.title == this.caseListItem.title
        );

        if (index == -1 && this.caseListItem.title != null) {
            this.caseEditList.push(this.caseListItem);
        }
    }

    /**
     * Removes a case from the document.
     * @param caseItem Removes a case from the document.
     */
    removeCase(caseItem) {
        const index = this.caseEditList.indexOf(caseItem);

        if (index >= 0) {
            this.caseEditList.splice(index, 1);
        }
    }

    /**
     * Adds a tag to the metadata form.
     * @param tag tag to be displayed in the list.
     * @param id  Used by existing tags on the doc to be sent back to server in case of updating value.
     * @param value Used by existing tags on the doc to display the value in the tag form.
     */
    addTag(tag, id?: number, value?: string) {
        let tagSelectVm = new TagSelectViewModel(tag, id, value);
        this.tagsEditList.push(tagSelectVm);
    }

    /**
     * Removes a tag from the list of tags.
     * @param index Index of the tag in the list to be removed.
     */
    removeTag(index) {
        if (index >= 0) {
            this.tagsEditList.splice(index, 1);
        }
    }

    /**
     * Opens a dialog box to create a new case.
     */
    openCreateCaseDialog() {
        const dialogRef = this.createCaseDialog.open(
            CreateCaseDialog2Component,
            {
                height: '45%',
                width: '30%',
            }
        );

        dialogRef.afterClosed().subscribe((data) => {
            // Auto add the case to the list once successfully created.
            if (data) {
                this.apiService.cases_Search(data.title, 1).subscribe(
                    (caseItem) => {
                        if (caseItem.length > 0) {
                            this.caseListItem = caseItem[0];
                            this.addCase();
                        }
                    },
                    (err) => {
                        this.toastr.error(
                            'An error occurred while searching for cases.'
                        );
                    }
                );
            }
        });
    }

    /**
     * Saves the metadata changes to the server.
     *
     * Copies the data from the temp vars to the correct object to be
     * sent to the server.
     */
    onSaveMetadata() {
        let error = this.validateMetadataTitle();
        if (error) {
            this.titleToEditError = error;
            return;
        }

        let dataToBeSaved = new DocumentMetadataViewModel();
        dataToBeSaved.documentId = this.document.id;
        dataToBeSaved.title = this.titleToEdit;
        dataToBeSaved.description = this.descriptionToEdit;
        dataToBeSaved.ownerId = this.ownerSelect.associatedUserId;
        dataToBeSaved.categories = this.categoryEditList;
        dataToBeSaved.cases = this.caseEditList;
        dataToBeSaved.important = this.importantToEdit;

        dataToBeSaved.tags = this.tagsEditList.map((x) => {
            let item = new DocumentTagViewModel();
            item.id = x.id;
            item.tagId = x.tagId;
            item.tagType = new TagTypeViewModel();
            item.tagType.id = x.tagId;
            item.tagType.name = x.name;
            item.value = x.value;
            return item;
        });

        let documentMetadataViewModelList: DocumentMetadataViewModel[] = [];
        documentMetadataViewModelList.push(dataToBeSaved);

        this.isSavingMetadata = true;
        this.apiService.metadata_Put(documentMetadataViewModelList).subscribe(
            (result) => {
                this.editMetaData = !this.editMetaData;

                let id = this.route.snapshot.paramMap.get('id');
                this.apiService.documents_Get(id).subscribe((data) => {
                    this.document = data;
                    this.isSavingMetadata = false;
                });
            },
            (err) => {
                this.toastr.error(
                    'An error occurred while saving the metadata.'
                );
                this.isSavingMetadata = false;
            }
        );
    }

    /**
     * Validates the documents title agains the 'required' and 'regex' fields.
     * */
    validateMetadataTitle() {
        if (this.document.requireDocumentTitle) {
            if (!this.titleToEdit) return 'Title is Required';

            if (this.document.documentTitleRegex) {
                // Do a regex check on the title.
                var patt = new RegExp(this.document.documentTitleRegex);
                var isValid = patt.test(this.titleToEdit);

                if (!isValid)
                    return 'Title does not match the required format.';
            }
        }

        return;
    }

    /*
     * Sends document to be rehydrated
     * */
    requestArchiveRetrieval() {
        this.requestedFromArchive = true;
        this.apiService.documents_UnArchiveDocument(this.document.id).subscribe(
            (data) => {
                this.toastr.success(
                    'Document requested from archive. Please allow up to 24 hours for completion. An email will be sent to you when complete.'
                );
            },
            (err) => {
                this.toastr.error(err.message);
            }
        );
        //Do API Call here
        //this.apiService.documents_RequestFromArchive(this.document.id).subcribe((data) =>
        //{
        //  this.toastr.success("Document requested from archive. Please allow up to 24 hours for completion.");
        //  Reload Page? Change statuses so page looks the same now as after a refresh? Something else?
        //});
    }

    backToSearchClicked() {
        this.location.back();
    }
}
