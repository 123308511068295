<div mat-dialog-title class="title">
    <div>Live Dashboard Options</div>
</div>

<mat-dialog-content class="content">
    <div class="form-container">
        <ul class="filterOptionList">
            <li>
                <mat-checkbox [(ngModel)]="data.filter.showOffline" i18n>Show Offline Devices</mat-checkbox>
            </li>
            <li>
                <mat-checkbox [(ngModel)]="data.filter.showVehicles" i18n>Show Vehicles</mat-checkbox>
            </li>
        </ul>
        <mat-divider></mat-divider>
        <mat-form-field>
            <mat-label>Sort By</mat-label>
            <mat-select [(value)]="data.sortField">
                <mat-option value="deviceStatus">Device Status</mat-option>
                <mat-option value="userName">User Name</mat-option>
                <mat-option value="deviceName">Device Name</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Sort Direction</mat-label>
            <mat-select [(value)]="data.sortDirection">
                <mat-option value="desc">Descending</mat-option>
                <mat-option value="asc">Ascending</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button color="primary" class="smx-primary-button" [mat-dialog-close]="data" i18n>Save</button>
    <button mat-button class="smx-secondary-button ml-1" (click)="onCancel()">
        Cancel
    </button>
</mat-dialog-actions>