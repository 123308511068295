<div class="outer-container">
    <div class="inner-container">
        <div class="mt-3">
            <h2 i18n>Edit User</h2>
            <hr/>
            <div class="d-flex flex-row mb-3" *ngIf="userLoaded">
                <div *ngIf="!user.isAccountVerified" class="mr-3">
                    <button mat-button class="smx-primary-button" (click)="resendVerificationEmail()"
                            [disabled]="sendingVerificationEmail" i18n>Resend
                        Invite
                    </button>
                </div>
                <div *ngIf="user.isAccountClosed===false" class="mr-3">
                    <button mat-button class="smx-critical-button" [matMenuTriggerFor]="confirmClose"
                            [disabled]="togglingAccount" *ngIf="user.isAccountClosed===false" i18n>Close Account
                    </button>
                    <mat-menu #confirmClose="matMenu">
                        <button type="button" mat-menu-item (click)="closeAccount()" i18n>Confirm Close</button>
                    </mat-menu>
                </div>
                <div *ngIf="user.isAccountClosed" class="mr-3">

                    <button mat-button class="smx-primary-button" *ngIf="user.isAccountClosed===true"
                            [matMenuTriggerFor]="confirmReopen" [disabled]="togglingAccount" i18n>Re-Open
                        Account
                    </button>
                    <mat-menu #confirmReopen="matMenu">
                        <button type="button" mat-menu-item (click)="openAccount()" i18n>Confirm Re-Open</button>
                    </mat-menu>
                </div>
                <div *ngIf="user.redactionSeatAvailable !== null" class="d-flex flex-row align-items-center">
                    <button mat-button *ngIf="user.redactionSeat===false" [disabled]="savingRedactionSeat" type="button"
                            (click)="assignRedactionSeat()" class="smx-primary-button d-flex align-items-center">
                        <ng-container i18n>Assign
                            Redaction Seat
                        </ng-container>
                        ({{user.redactionSeatAvailable}}
                        <ng-container i18n>Available
                        </ng-container>
                        )
                    </button>
                    <button mat-button *ngIf="user.redactionSeat===true" [disabled]="savingRedactionSeat" type="button"
                            (click)="removeRedactionSeat()" class="smx-critical-button d-flex align-items-center">
                        <ng-container i18n>Remove
                            Redaction Seat
                        </ng-container>
                        ({{user.redactionSeatAvailable}}
                        <ng-container i18n>Available
                        </ng-container>
                        )
                    </button>
                </div>
            </div>
            <div *ngIf="!userLoaded">
                <mat-spinner color="accent"></mat-spinner>
            </div>
            <div *ngIf="userLoaded">
                <form name="register" #register="ngForm" (ngSubmit)="saveUserEdit()" novalidate>
                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <label class="control-label" i18n>Username</label>
                            <div>
                                {{user.userName != null ? user.userName : 'Not Set'}}
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <label class="control-label" i18n>Email</label>
                            <mat-form-field appearance="fill" hintLabel="Changing email will require verification."
                                            style="display:block;">
                                <input matInput type="email" name="email" #email="ngModel" required
                                       [(ngModel)]="user.email" userId="{{user.iD}}" emailExistsValidator/>
                                <mat-error *ngIf="email.errors?.required" i18n>Email is required.</mat-error>
                                <mat-error *ngIf="email.errors?.emailExistsValidator" i18n>You must have a default
                                    retention set.
                                </mat-error>
                                <mat-error *ngIf="email.errors?.emailValid===false" i18n>Please enter a valid email.
                                </mat-error>
                                <mat-error *ngIf="email.errors?.emailExists===true" i18n>A user with that email already
                                    exists.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <div class="form-group">
                                <label class="control-label" i18n>Last Name</label>
                                <mat-form-field appearance="fill" hintLabel="" style="display:block;">
                                    <input matInput type="text" [(ngModel)]="user.lastName" name="userLastName"
                                           style="padding-top:0em;"/>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-6">
                            <div class="form-group">
                                <label class="control-label" i18n>First Name</label>
                                <mat-form-field appearance="fill" hintLabel="" style="display:block;">
                                    <input matInput type="text" [(ngModel)]="user.firstName" name="userFirstName"
                                           style="padding-top:0em;"/>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <div class="form-group">
                                <label class="control-label" i18n>Video Identifier</label>
                                <mat-form-field appearance="fill" hintLabel="Identifies user in recorded filename."
                                                style="display:block;">
                                    <input matInput required type="text" #syncUserNameAlias="ngModel"
                                           [(ngModel)]="user.syncUserNameAlias" name="syncUserNameAlias"
                                           userAliasValidator="{{user.iD}}" pattern="[a-zA-Z0-9]+" maxlength="15"
                                           style="padding-top:0em;"/>
                                    <mat-error *ngIf="syncUserNameAlias.errors?.aliasInvalid" i18n>User alias already in
                                        use by another user. Please enter a different alias.
                                    </mat-error>
                                    <mat-error *ngIf="syncUserNameAlias.errors?.pattern" i18n>User alias may only
                                        contain the following characters: a-z A-Z 0-9 .
                                    </mat-error>
                                    <mat-error *ngIf="syncUserNameAlias.errors?.maxlength" i18n>User alias can be a
                                        maximum of 15 characters.
                                    </mat-error>
                                    <mat-hint align="end">{{syncUserNameAlias.value?.length || 0}}/15</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="form-group">
                                <label class="control-label" i18n>Mobile Phone</label>
                                <mat-form-field appearance="fill" style="display:block;">
                                    <input matInput type="text" [(ngModel)]="user.mobilePhone" name="mobilePhone"/>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <div class="form-group">
                                <label class="control-label" i18n>Title</label>
                                <mat-form-field appearance="fill" style="display:block;">
                                    <input matInput type="text" [(ngModel)]="user.title" name="title"/>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-6">
                            <label class="control-label" i18n>Agency/Org</label>
                            <mat-form-field appearance="fill" style="display:block;">
                                <input matInput type="text" [(ngModel)]="user.agencyOrg" name="agencyOrg"/>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <div class="form-group">
                                <label class="control-label" i18n>Country</label>
                                <mat-form-field appearance="fill" hintLabel="The user's country."
                                                style="display:block;">
                                    <mat-select [(ngModel)]="user.country" name="country" #country="ngModel"
                                                (selectionChange)="countryChanged()">
                                        <mat-option *ngFor="let option of countryNameList" [value]="option">
                                            {{option}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="form-group">
                                <label class="control-label" i18n>State</label>
                                <mat-form-field appearance="fill" hintLabel="The user's state." style="display:block;">
                                    <mat-select [(ngModel)]="user.state" name="state" #state="ngModel">
                                        <mat-option *ngFor="let option of stateNameList" [value]="option">
                                            {{option}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <label class="control-label" i18n>Group</label>
                            <mat-form-field appearance="fill" hintLabel="The user's group." style="display:block;">
                                <mat-select required [(ngModel)]="user.group" name="group" #group="ngModel">
                                    <mat-option *ngFor="let option of groups" [value]="option.name">
                                        <strong>{{option.name}}</strong> {{option.description}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="group.errors?.required" i18n>You must choose a group for this user.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <label class="control-label" i18n>RFID</label>
                            <mat-form-field appearance="fill" hintLabel="Format: B4NNNNNNN" style="display:block;">
                                <input matInput type="text" #rfid="ngModel" placeholder="Ex: B4000001" i18n-placeholder
                                       [(ngModel)]="user.rFID" minlength="8" maxlength="8" name="rfid"
                                       (change)="CheckForRFIDOverwrite()"/>
                                <mat-error *ngIf="rfid.errors?.maxlength || rfid.errors?.minlength" i18n>RFID must be
                                    exactly 8 characters.
                                </mat-error>
                                <mat-hint align="end">{{rfid.value?.length || 0}}/8</mat-hint>
                            </mat-form-field>
                            <div style="color:red;" *ngIf="rfidOverwrite" i18n>RFID tag is already assigned to a user.</div>
                        </div>
                    </div>

                    <div class="form-group" ng-if="!vm.isEditForm">
                        <div class="col-sm-offset-3">
                            <h4 class="text-danger">{{formError}}</h4>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-7 align-self-center">
                            <h2 class="d-flex justify-content-between align-items-center">
                                <ng-container i18n>Group Permissions</ng-container>
                                <button mat-icon-button type="button" (click)="openHelpGroupPermissionsDialog()">
                                    <i class="fas fa-question-sign"></i>
                                    <fa-icon [icon]="['fas','question-circle']"></fa-icon>
                                </button>
                            </h2>

                            <table class="permissions-table table mx-auto">
                                <thead>
                                <tr>
                                    <th i18n>Group</th>
                                    <th i18n>View</th>
                                    <th i18n>Share</th>
                                    <th i18n>View HD / Download</th>
                                    <th i18n>Edit</th>
                                    <ng-container *ngIf="currentLoggedInUser.isLivePlan">
                                        <th i18n>Live Map</th>
                                        <th i18n>Live View</th>
                                        <th i18n>Remote Retrieval</th>
                                    </ng-container>
                                    <th i18n>All</th>
                                </tr>
                                </thead>
                                <tbody>

                                <ng-container *ngIf="currentLoggedInUser.isLivePlan">

                                    <tr *ngFor="let perm of user.groupPermissions"
                                        [class]="{'table-active': perm.name===user.group}">
                                        <td>{{perm.name}}</td>
                                        <td>
                                            <mat-checkbox name="{{perm.name}}_view_check" [(ngModel)]="perm.view"></mat-checkbox>
                                        </td>
                                        <td>
                                            <mat-checkbox name="{{perm.name}}_share_check" [(ngModel)]="perm.share">

                                            </mat-checkbox>
                                        </td>
                                        <td>
                                            <mat-checkbox name="{{perm.name}}_download_check" [(ngModel)]="perm.download"></mat-checkbox>
                                        </td>
                                        <td>
                                            <mat-checkbox name="{{perm.name}}_edit_check" [(ngModel)]="perm.edit"> </mat-checkbox>
                                        </td>
                                        <td>
                                            <mat-checkbox name="{{perm.name}}_liveMap_check" [(ngModel)]="perm.liveMap"></mat-checkbox>
                                        </td>
                                        <td>
                                            <mat-checkbox name="{{perm.name}}_liveView_check" [(ngModel)]="perm.liveView"></mat-checkbox>
                                        </td>
                                        <td>
                                            <mat-checkbox name="{{perm.name}}_removeRetrieval_check" [(ngModel)]="perm.remoteRetrieval"> </mat-checkbox>
                                        </td>
                                        <td>
                                            <mat-checkbox [checked]="(perm.share===true && perm.view===true && perm.download===true && perm.edit===true
                                                                && perm.liveMap===true && perm.liveView===true && perm.remoteRetrieval===true)"
                                                          (change)="checkAll(perm)"></mat-checkbox>
                                        </td>
                                    </tr>
                                </ng-container>

                                <ng-container *ngIf="!currentLoggedInUser.isLivePlan">

                                    <tr *ngFor="let perm of user.groupPermissions"
                                        [class]="{'table-active': perm.name===user.group}">
                                        <td>{{perm.name}}</td>
                                        <td>
                                            <mat-checkbox name="{{perm.name}}_view_check" [(ngModel)]="perm.view" ></mat-checkbox>
                                        </td>
                                        <td>
                                            <mat-checkbox name="{{perm.name}}_share_check" [(ngModel)]="perm.share"
                                                          ></mat-checkbox>
                                        </td>
                                        <td>
                                            <mat-checkbox name="{{perm.name}}_download_check"
                                                          [(ngModel)]="perm.download"
                                                          ></mat-checkbox>
                                        </td>
                                        <td>
                                            <mat-checkbox name="{{perm.name}}_edit_check" [(ngModel)]="perm.edit"
                                                          ></mat-checkbox>
                                        </td>
                                        <td>
                                            <mat-checkbox [checked]="(perm.share===true && perm.view===true && perm.download===true && perm.edit===true
                                                                && perm.liveMap===true && perm.liveView===true && perm.remoteRetrieval===true)"
                                                          (change)="checkAll(perm)"></mat-checkbox>
                                        </td>
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-sm-4">
                            <h2 class="d-flex align-items-center">
                                <ng-container i18n>Administrative Rights</ng-container>
                                <button mat-icon-button type="button" (click)="openAdministrativeRightsHelpDialog()"
                                        title="Open Administrative Rights Help" i18n-title>
                                    <fa-icon [icon]="['fas','question-circle']"></fa-icon>
                                </button>
                            </h2>
                            <div>
                                <mat-checkbox name="administrativeRightsAllCheckBox"
                                              [(ngModel)]="administrativeRightsAllCheckBox"
                                              (change)="checkAllAdministrativeRights()" i18n>Select All
                                </mat-checkbox>
                            </div>
                            <ul class="list-group">
                                <div *ngFor="let right of user.rights">
                                    <li class="list-group-item" *ngIf="isAdminRight(right)">
                                        <mat-checkbox [(ngModel)]="right.value" name="{{right.name}}"
                                                      (change)="administrativeRightOptionToggled()"
                                                      title="{{translate(right.name)}}" i18n-title>
                                            <div class="text-wrap">
                                                {{translate(right.name)}}
                                            </div>
                                        </mat-checkbox>
                                    </li>
                                </div>

                            </ul>
                        </div>
                        <div class="col-md-4 col-sm-4">
                            <h2 class="d-flex align-items-center">
                                <ng-container i18n>Own Document Permissions</ng-container>
                                <button mat-icon-button type="button" (click)="openDocumentPermissionsHelpDialog()"
                                        title="Open Document Permissions Help" i18n-title>
                                    <fa-icon [icon]="['fas','question-circle']"></fa-icon>
                                </button>
                            </h2>

                            <div>
                                <mat-checkbox [(ngModel)]="ownDocumentsRightsAllCheckBox"
                                              name="ownDocumentsRightsAllCheckBox"
                                              (change)="checkAllOwnDocumentsRights()" i18n>
                                    Select All
                                </mat-checkbox>
                                <ul class="list-group">
                                    <div *ngFor="let right of user.rights">
                                        <li class="list-group-item" *ngIf="!isAdminRight(right)">
                                            <mat-checkbox [(ngModel)]="right.value" name="{{right.name}}"
                                                          (change)="ownDocumentsRightOptionToggled()"
                                                          title="{{translate(right.name)}}" i18n-title>
                                                <div class="text-wrap">
                                                    {{translate(right.name)}}
                                                </div>
                                            </mat-checkbox>
                                        </li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="formError && formError !== ''" class="row mt-4">
                        <div class="col">
                            <div class="alert alert-danger">
                                {{formError}}
                            </div>
                        </div>
                    </div>

                    <div *ngIf="rfidOverwrite" class="row">
                        <div class="col">
                            <h4 class="text-danger">RFID tag is already assigned to a user.</h4>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <button mat-button [disabled]="register.invalid || isSaving || rfidOverwrite" type="submit"
                                    class="smx-primary-button align-items-center mt-2">
                                <div *ngIf="isSaving===false">
                                    <fa-icon [icon]="['fas','save']" class="mr-1"></fa-icon>
                                    <span i18n>Save</span>
                                </div>
                                <div class="saving-button" *ngIf="isSaving === true">
                                    <mat-spinner color="accent" diameter=30></mat-spinner>
                                    <ng-container i18n>Saving...</ng-container>
                                </div>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<style>
    mat-form-field {
        display: block;
        max-width: 450px;
    }
</style>
