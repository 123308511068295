<div mat-dialog-title class="title">
    <div>Request Video from {{user?.userName ?? ""}} ({{data.deviceName ? data.deviceName : data.deviceSerial }})</div>
    <div class="stuff-in-title" style="margin-left: 1rem;">{{ deviceType }}</div>
</div>

<mat-dialog-content class="content">
    <div *ngIf="deviceType === 'BC4'" class="description-text">
        Request allows non-event video to be automatically transferred the next time the unit is on a non-metered network connection (Wi-Fi or Docking Station).
    </div>
    <div *ngIf="deviceType !== 'BC4'" class="description-text">
        Request allows non-event video to be automatically transferred the next time the unit is on a non-metered network connection (Wi-Fi or Docking Station).
        Select the desired video thumbnail(s) for camera choice and enter the desired Start Time and Length of video.
    </div>
    <br>
    <form [formGroup]="form">
        <div>
            <div *ngIf="deviceType !== 'BC4'" id="Camera">
                <ng-container *ngIf="cameras_loaded === false">
                    <div class="container-fluid">
                        <div id="cameraSelect_container" class="row">
                            Loading...
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="cameras_loaded">
                    <mat-selection-list multiple formControlName="cameras">
                        <mat-list-option
                            *ngFor="let camera of cameras"
                            [value]="camera"
                        >
                            <img class="preview-image" [src]="camera.hasPreviewImage ? camera.image : '/assets/images/no_preview_image_available.png'" />
                            <div>
                                {{ camera.cameraNumber }} -
                                {{ camera.cameraName }}
                            </div></mat-list-option
                        >
                    </mat-selection-list>
                    <!-- <div
                        *ngFor="let camera of cameras"
                        class="cameraSelect_camera"
                    >
                        <div class="camera-container">
                            <img [src]="camera.image" />
                            <div>
                                {{ camera.cameraNumber }} -
                                {{ camera.cameraName }}
                            </div>
                        </div>
                    </div> -->
                </ng-container>
            </div>
        </div>

        <div class="form-controls">
            <label>Start Time*</label>
            <app-daterangepicker tabindex="2" [rangeMode]="false" (change)="daterangepicker_change()" [(start)]="daterangepicker_start" [(end)]="daterangepicker_end"></app-daterangepicker>

            <label>Length of Request*</label>
            <mat-form-field appearance="outline" class="w-100">
                <mat-select tabindex="1" formControlName="length">
                    <mat-option
                        *ngFor="let length of allLengths"
                        [value]="length.value"
                        >{{ length.description }}</mat-option
                    >
                </mat-select>
            </mat-form-field>

            <div>End Time</div>
            <div>{{ calculatedEndTime ? calculatedEndTime : 'Select a start time and length first' }}</div>
<!--

            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Request notes</mat-label>
                <textarea matInput formControlName="notes"></textarea>
            </mat-form-field>
     -->
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button class="smx-primary-button" (click)="createRequest()">
        Create Request
    </button>
    <button mat-button class="smx-secondary-button ml-1" (click)="cancel()">
        Cancel
    </button>
</mat-dialog-actions>
