import { Component, HostListener, QueryList, ViewChildren } from '@angular/core';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { from } from 'rxjs';
import { HubConnection } from '@microsoft/signalr';
import { Loader } from '@googlemaps/js-api-loader';
import GUI from 'lil-gui';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SnackbarService } from '../../services/snackbar.service';
import { LiveInactivityDialogComponent } from './live-inactivity-dialog/live-inactivity-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LiveDownloadDialogComponent } from './live-download-dialog/live-download-dialog.component';
import * as signalR from '@microsoft/signalr';
import { LiveFilterDialog } from './live-filter-dialog/live-filter-dialog.component';

import { LiveCameraSelectDialogComponent } from './live-camera-select-dialog/live-camera-select-dialog.component';
import { HttpClient } from '@angular/common/http';
import { CreateRequestDialogComponent } from '../../shared/components/create-request-dialog/create-request-dialog.component';
import { DeviceService, DevicesService } from '../../services/api2';
// todo move

function debounce(delay: number) {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        let timeout: any;
        const original = descriptor.value;

        descriptor.value = function (...args: any[]) {
            clearTimeout(timeout);
            timeout = setTimeout(() => original.apply(this, args), delay);
        };

        return descriptor;
    };
}

export function delay(time: number): Promise<number> {
    return new Promise((resolve) => setTimeout(resolve, time));
}

export interface LiveViewLinkResponse {
    url: string;
    authToken: string;
    liveViewUID: string;
}

export interface IReverseGeoCodeViewModel {
    city: string;
    state: string;
    address: string;
    deviceSerial: string;
}

export interface IDeviceData {
    deviceId: string;
    deviceName: string;
    serialNumber: string;
    assignedUsername: string;
    firstName: string | null;
    lastName: string | null;
    latitude: number;
    longitude: number;
    address: string;
    city: string;
    state: string;
    isOnline: boolean;
    isBodycam: boolean;
    hasValidLocation: boolean;
    lastConnected: string;
    deviceTypeName: string;
    deviceModelNumber: string;
    hasLiveMapPermission: boolean;
    hasLiveViewPermission: boolean;
    hasCreateVideoRequestPermission: boolean;
    cameras: Array<IDeviceCameraViewModel>;
}

export interface IDeviceCameraViewModel {
    cameraTitle: string;
    online: boolean;
    recording: boolean;
    index?: number;
    thumbnail?: string;
}

export interface IDeviceViewModel {
    device: IDeviceData;
    markerElement: any | null;
    marker: any | null;
    isCollapsed: boolean;
    location: string | null;
    basicContent: any | null;
    detailsContent: any | null;
}

export interface ILiveComponentSearchFilter {
    searchTerm: string;
}

interface ILiveComponentState {
    hasViewInitCompleted?: boolean;
    webSocket_disconnectedAt?: Date;
    webSocket_mostRecentReconnectAttemptAt?: Date;
    webSocket_errorAt?: Date;
    webSocket_reconnectedAt?: Date;
    webSocket_hasHadAtLeastOneInitialConnectionAttempt?: boolean;
    maps_startedLoadingApiAt?: Date;
    maps_completedLoadingApiAt?: Date;
    lastSeenActivityAt?: Date;
    inactivityMessage_poppedAt?: Date;
    inactivityMessage_closedByUserAt?: Date;
    inactivityMessage_wasInactiveAtLeastOnce?: boolean;
    lastDeviceListRefresh_status?: string;
    lastDeviceListRefresh_startedAt?: Date;
    lastDeviceListRefresh_completedAt?: Date;
    lastDeviceListRefresh_data?: IDeviceViewModel[];
}

@Component({
    selector: 'app-live',
    templateUrl: './live.component.html',
    styleUrl: './live.component.css',
})
export class LiveComponent {
    debugGui: GUI;
    debugState = {
        useTestingDevices: false,
        testPositionLat: 0.0,
        testPositionLong: 0.0,
        simulateGpsLoss: true,
        showOtherDevices: false,
        devicesToAddToList: 0,
        hideAllDevices: false,
        pauseUpdates: false,
        showTestPopups: () => {
            this.toastr.success('Happy test.');
            setTimeout(() => {
                this.toastr.error('Sad test.');
            }, 2000);
        },
        fakeInactivity: () => {
            const oldInactivityMillis = this.inactivityTimeBeforePopupMillis;
            this.inactivityTimeBeforePopupMillis = 100;
            setTimeout(() => {
                this.inactivityTimeBeforePopupMillis = oldInactivityMillis;
            }, 2000);
        },
    };

    oldState: ILiveComponentState = {};
    newState: ILiveComponentState = {};
    inactivityEvents = [
        'mousedown',
        'mousemove',
        'keypress',
        'scroll',
        'touchstart',
        'click',
        'keydown',
        'resize',
        'input',
        'change',
    ];
    inactivityEventHandlers = [];
    inactivityTimeBeforePopupMillis = 60 * 1000;

    minTimeBeforeDeviceListRefreshMillis = 5 * 1000;
    minTimeBetweenReconnectAttemptsMillis = 5 * 1000;

    state_hasCompletedAtLeastOneUpdateDisplay = false;

    // Pro-Vision offices.
    defaultLocationLat = 42.80723492899391;
    defaultLocationLong = -85.6757442080222;

    @ViewChildren('devicePanel') panels: QueryList<MatExpansionPanel>;
    devices_asOfLastUpdate: IDeviceViewModel[] = [];
    filter: ILiveComponentSearchFilter = { searchTerm: '' };
    map: google.maps.Map;
    clusterer: MarkerClusterer;

    conn: HubConnection;
    autoRefreshTimerId: NodeJS.Timeout | null = null;
    mainTimerId: NodeJS.Timeout | null = null;

    currentUserName: string | null = null;

    filterSettings: any = {
        sortField: 'deviceStatus',
        sortDirection: 'asc',
        addressDisplay: 'cityState',
        filter: {
            showOffline: true,
            showBodycams: true,
            showVehicles: true,
        },
    };

    constructor(private toastr: SnackbarService, private dialog: MatDialog, private http: HttpClient, private devicesService: DevicesService) {
        this.inactivityEvents.forEach((event) => {
            const listener = () => this.inactivityEventHandler();
            document.addEventListener(event, listener, { passive: true });
            this.inactivityEventHandlers.push([event, listener]);
        });

        this.autoRefreshTimerId = setInterval(() => { }, 10 * 1000);

        const mainTimerMillis = 500;
        this.mainTimerId = setInterval(() => this.main(), mainTimerMillis);

        this.conn = new signalR.HubConnectionBuilder().withUrl('/live/hub').build();
        this.conn.serverTimeoutInMilliseconds = 30 * 1000;
        this.conn.keepAliveIntervalInMilliseconds = 3 * 1000;
        this.conn.on('GetDevicesList_Callback', (data) => {
            this.newState.lastDeviceListRefresh_completedAt = new Date();
            const updatedDevices = data.map((x) => this.createDeviceVMForDevice(x));
            this.newState.lastDeviceListRefresh_data = updatedDevices;
        });
        this.conn.on('GetReverseGeoCode_Callback', (data) => {
            const result: IReverseGeoCodeViewModel = data[0];
            const deviceVM = this.devices_asOfLastUpdate.find((x) => x.device.serialNumber === result.deviceSerial);
            deviceVM.location = result.address;
            this.updateContent(deviceVM);
        });
        this.conn.onclose(() => {
            this.newState.webSocket_disconnectedAt = new Date();
        });
    }

    inactivityEventHandler() {
        this.newState.lastSeenActivityAt = new Date();
    }

    oldState_isInactive() {
        if (this.oldState.lastSeenActivityAt) {
            const currentDateTime = new Date();
            const timeElapsedSinceLastSeenActivity = Math.abs(
                this.oldState.lastSeenActivityAt.getTime() - currentDateTime.getTime()
            );
            if (timeElapsedSinceLastSeenActivity >= this.inactivityTimeBeforePopupMillis) {
                return true;
            }
            if (this.oldState.inactivityMessage_poppedAt && !this.oldState.inactivityMessage_closedByUserAt) {
                return true;
            }
        }

        return false;
    }

    main() {
        try {
            const currentDateTime = new Date();

            if (this.oldState.hasViewInitCompleted) {
                if (this.conn.state !== 'Connected') {
                    // console.log('websocket detected as disconnected, going to reconnect if active');
                    if (!this.oldState_isInactive()) {
                        console.log("going to try reconnecting if we've waited long enough between attempts");
                        let timeSinceLastReconnectAttempt = 0;
                        if (this.oldState.webSocket_mostRecentReconnectAttemptAt) {
                            timeSinceLastReconnectAttempt = Math.abs(
                                this.oldState.webSocket_mostRecentReconnectAttemptAt.getTime() - currentDateTime.getTime()
                            );
                        }

                        if (
                            !this.oldState.webSocket_hasHadAtLeastOneInitialConnectionAttempt ||
                            !this.oldState.webSocket_mostRecentReconnectAttemptAt ||
                            timeSinceLastReconnectAttempt >= this.minTimeBetweenReconnectAttemptsMillis
                        ) {
                            if (this.oldState.webSocket_hasHadAtLeastOneInitialConnectionAttempt) {
                                this.newState.webSocket_mostRecentReconnectAttemptAt = new Date();
                                this.toastr.error('Disconnected from live service, trying to reconnect...');
                                console.log('disconnected attempting to reconnect');
                            }

                            console.log('attempting to connect to signalr hub');
                            this.newState.webSocket_hasHadAtLeastOneInitialConnectionAttempt = true;
                            try {
                                this.conn
                                    .start()
                                    .then(() => {
                                        if (this.oldState.webSocket_errorAt || this.oldState.inactivityMessage_wasInactiveAtLeastOnce) {
                                            // let the last disconnected message timeout a bit more
                                            setTimeout(() => this.toastr.success('Reconnected.'), 2000);
                                        }
                                        this.newState.webSocket_errorAt = null;
                                        this.newState.webSocket_mostRecentReconnectAttemptAt = null;
                                        this.newState.webSocket_disconnectedAt = null;
                                        this.newState.webSocket_reconnectedAt = new Date();
                                    })
                                    .catch((err) => {
                                        console.error(err);
                                    });
                            } catch (connErr) {
                                console.error(connErr);
                            }
                        }
                    }
                }
            }

            if (this.oldState.hasViewInitCompleted && !this.oldState.maps_startedLoadingApiAt) {
                console.log('attempting to load google maps api');
                this.newState.maps_startedLoadingApiAt = new Date();
                this.loadMapsApi()
                    .then(() => {
                        this.newState.maps_completedLoadingApiAt = new Date();
                    })
                    .catch((err) => {
                        // todo handle map api failing?
                        console.error(err);
                    });
            }

            if (this.oldState.lastSeenActivityAt) {
                const timeElapsedSinceLastSeenActivity = Math.abs(
                    this.oldState.lastSeenActivityAt.getTime() - currentDateTime.getTime()
                );
                if (timeElapsedSinceLastSeenActivity >= this.inactivityTimeBeforePopupMillis) {
                    if (!this.oldState.inactivityMessage_poppedAt) {
                        this.newState.inactivityMessage_poppedAt = currentDateTime;
                        this.openInactivityDialog();
                        console.log('inactive');
                    }
                }
            }

            if (this.oldState.inactivityMessage_closedByUserAt) {
                console.log('resetting inactivity');
                this.newState.lastSeenActivityAt = currentDateTime;
                this.newState.inactivityMessage_poppedAt = null;
                this.newState.inactivityMessage_closedByUserAt = null;
                this.newState.inactivityMessage_wasInactiveAtLeastOnce = true;
            }

            if (this.conn.state === 'Connected') {
                if (!this.oldState.lastDeviceListRefresh_startedAt) {
                    if (!this.oldState_isInactive()) {
                        console.log('sending request for new device list data');
                        this.newState.webSocket_errorAt = null;
                        this.newState.lastDeviceListRefresh_startedAt = currentDateTime;
                        this.newState.lastDeviceListRefresh_status = 'sent';
                        if (this.debugState.useTestingDevices) {
                            this.conn
                                .invoke('GetDevicesList', this.debugState)
                                .then(() => {
                                    this.newState.lastDeviceListRefresh_status = 'waiting on callback';
                                })
                                .catch((err) => {
                                    this.newState.lastDeviceListRefresh_status = 'error';
                                    this.newState.webSocket_errorAt = new Date();
                                    console.group('error sending device list request');
                                    console.log(err);
                                    console.groupEnd();
                                });
                        } else {
                            this.conn
                                .invoke('GetDevicesList', null)
                                .then(() => {
                                    this.newState.lastDeviceListRefresh_status = 'waiting on callback';
                                })
                                .catch((err) => {
                                    this.newState.lastDeviceListRefresh_status = 'error';
                                    this.newState.webSocket_errorAt = new Date();
                                    console.group('error sending device list request');
                                    console.log(err);
                                    console.groupEnd();
                                });
                        }
                    }
                }
            }

            if (this.oldState.lastDeviceListRefresh_completedAt) {
                if (this.oldState.lastDeviceListRefresh_data) {
                    console.log('completed update to device list');
                    //console.log("old state: ", this.oldState.lastDeviceListRefresh_data)
                    this.handleDeviceUpdateOrFilterChange(this.oldState.lastDeviceListRefresh_data);
                    this.newState.lastDeviceListRefresh_data = null;
                    this.newState.lastDeviceListRefresh_status = null;
                    this.state_hasCompletedAtLeastOneUpdateDisplay = true;
                }
            }

            if (this.oldState.lastDeviceListRefresh_startedAt && this.oldState.lastDeviceListRefresh_completedAt) {
                const timeSinceCompleted = Math.abs(
                    this.oldState.lastDeviceListRefresh_completedAt.getTime() - currentDateTime.getTime()
                );
                if (timeSinceCompleted >= this.minTimeBeforeDeviceListRefreshMillis) {
                    console.log('time between device list updates is too long, getting new device list');
                    this.newState.lastDeviceListRefresh_startedAt = null;
                    this.newState.lastDeviceListRefresh_completedAt = null;
                }
            }

            if (this.oldState.lastDeviceListRefresh_status === 'error') {
                console.log('last attempt to get device list failed, resetting device list state');
                this.newState.lastDeviceListRefresh_startedAt = null;
                this.newState.lastDeviceListRefresh_completedAt = null;
                this.newState.lastDeviceListRefresh_data = null;
                this.newState.lastDeviceListRefresh_status = null;
                // todo consider restarting the conn here too
            }
        } catch (err) {
            console.error(err);
        }

        this.oldState = this.newState;
        this.newState = { ...this.oldState };
    }

    handleDeviceUpdateOrFilterChange(devices_newUpdate: IDeviceViewModel[]) {
        if (this.debugState.pauseUpdates) {
            return;
        }
        let testDevice = devices_newUpdate.find(x => x.device.serialNumber.includes("2207B400230"));

        const bounds = new google.maps.LatLngBounds();
        let doAnyDevicesHaveMapPermissions = false;
        for (const deviceVM of devices_newUpdate) {
            if (deviceVM.device.hasLiveMapPermission) {
                doAnyDevicesHaveMapPermissions = true;
                break;
            }
        }
        const mainMapElem = document.getElementById('main-map');
        if (doAnyDevicesHaveMapPermissions) {
            mainMapElem.style.display = 'unset';
        } else {
            mainMapElem.style.display = 'none';
        }

        devices_newUpdate = devices_newUpdate.filter((deviceVM: IDeviceViewModel) => {
            if (this.filterSettings.filter.showOffline == false && deviceVM.device.isOnline == false) {
                return false;
            }
            if (this.filterSettings.filter.showBodycams == false && deviceVM.device.isBodycam == true) {
                return false;
            }
            if (this.filterSettings.filter.showVehicles == false && deviceVM.device.isBodycam == false) {
                return false;
            }
            if (this.filter.searchTerm !== '') {
                const searchTerm = this.filter.searchTerm.toLowerCase();

                if (
                    deviceVM.device.deviceName.toLowerCase().indexOf(searchTerm) === -1 &&
                    (deviceVM.device.assignedUsername == null ||
                        deviceVM.device.assignedUsername.toLowerCase().indexOf(searchTerm) === -1)
                ) {
                    return false;
                }
            }

            return true;
        });

        devices_newUpdate.sort((aRaw, bRaw) => {
            const a = aRaw.device;
            const b = bRaw.device;

            const aSortName = this.getDeviceSortName(a);
            const bSortName = this.getDeviceSortName(b);

            let result = 0;

            if (this.filterSettings.sortField === 'deviceName') {
                if (a.isOnline && !b.isOnline) {
                    result = -1;
                } else if (!a.isOnline && b.isOnline) {
                    result = 1;
                } else {
                    result = aSortName < bSortName ? -1 : 1;
                }
            } else if (this.filterSettings.sortField === 'deviceStatus') {
                if (a.isOnline && !b.isOnline) {
                    result = -1;
                } else if (!a.isOnline && b.isOnline) {
                    result = 1;
                } else {
                }
            } else if (this.filterSettings.sortField === 'userName') {
                if (a.isOnline && !b.isOnline) {
                    result = -1;
                } else if (!a.isOnline && b.isOnline) {
                    result = 1;
                } else if (a.assignedUsername === null && b.assignedUsername !== null) {
                    result = 1;
                } else if (a.assignedUsername !== null && b.assignedUsername === null) {
                    result = -1;
                } else {
                    result = a.assignedUsername < b.assignedUsername ? -1 : 1;
                }
            }

            if (result === 0) {
                result = a.serialNumber < b.serialNumber ? -1 : 1;
            }

            if (this.filterSettings.sortDirection === 'desc' && result !== 0) {
                result = result * -1;
            }

            return result;
        });

        const addedDevices: IDeviceViewModel[] = [];
        const removedDevices: IDeviceViewModel[] = [];
        const updatedDevices: [IDeviceViewModel, IDeviceViewModel][] = [];

        for (const deviceVM of devices_newUpdate) {
            const possibleMatchingDeviceForUpdate = this.devices_asOfLastUpdate.find(
                (x) => x.device.serialNumber === deviceVM.device.serialNumber
            );
            if (possibleMatchingDeviceForUpdate) {
                updatedDevices.push([possibleMatchingDeviceForUpdate, deviceVM]);
            } else {
                addedDevices.push(deviceVM);
            }
        }

        for (const deviceVM of this.devices_asOfLastUpdate) {
            if (
                !addedDevices.find((x) => x.device.serialNumber === deviceVM.device.serialNumber) &&
                !updatedDevices.find((x) => x[0].device.serialNumber === deviceVM.device.serialNumber)
            ) {
                removedDevices.push(deviceVM);
            }
        }

        for (const [oldDeviceVM, newDeviceVM] of updatedDevices) {
            // Keep the last good location around if we had one before.
            if (!newDeviceVM.location && oldDeviceVM.location) {
                newDeviceVM.location = oldDeviceVM.location;
            }
            if (
                oldDeviceVM.device.latitude !== newDeviceVM.device.latitude ||
                oldDeviceVM.device.longitude !== newDeviceVM.device.longitude
            ) {
                oldDeviceVM.device.latitude = newDeviceVM.device.latitude;
                oldDeviceVM.device.longitude = newDeviceVM.device.longitude;

                if (oldDeviceVM.marker) {
                    this.clusterer.removeMarker(oldDeviceVM.marker);
                    oldDeviceVM.marker.position = new google.maps.LatLng(
                        newDeviceVM.device.latitude,
                        newDeviceVM.device.longitude
                    );
                    this.clusterer.addMarker(oldDeviceVM.marker);
                }
            }

            newDeviceVM.marker = oldDeviceVM.marker;
            let validLocationChange = newDeviceVM.device.hasValidLocation !== oldDeviceVM.device.hasValidLocation;
            let onlineStatusChange = newDeviceVM.device.isOnline !== oldDeviceVM.device.isOnline;            

            if (validLocationChange || onlineStatusChange) {
                oldDeviceVM.basicContent.remove();
                oldDeviceVM.detailsContent.remove();
                newDeviceVM.basicContent = this.buildBasicContent(newDeviceVM);
                newDeviceVM.detailsContent = this.buildDetailsContent(newDeviceVM);
            } else {
                newDeviceVM.basicContent = oldDeviceVM.basicContent;
                newDeviceVM.detailsContent = oldDeviceVM.detailsContent;
            }
            newDeviceVM.isCollapsed = oldDeviceVM.isCollapsed;

            this.updateContent(newDeviceVM);
        }

        for (const deviceVM of addedDevices) {
            if (deviceVM.device.hasLiveMapPermission) {
                deviceVM.basicContent = this.buildBasicContent(deviceVM);
                deviceVM.detailsContent = this.buildDetailsContent(deviceVM);
                const newMarker = new google.maps.marker.AdvancedMarkerElement({
                    position: new google.maps.LatLng(deviceVM.device.latitude, deviceVM.device.longitude),
                    //map: this.mainMap,
                    content: deviceVM.isCollapsed ? deviceVM.basicContent : deviceVM.detailsContent,
                });

                newMarker.addListener('click', () => {
                    this.devices_asOfLastUpdate
                        .filter((x) => x.device.serialNumber !== deviceVM.device.serialNumber)
                        .forEach((x) => {
                            x.isCollapsed = true;
                            this.updateContent(x);
                        });
                    const myVm = this.devices_asOfLastUpdate.find((x) => x.device.serialNumber === deviceVM.device.serialNumber);
                    if (myVm) {
                        myVm.isCollapsed = false;
                        this.updateContent(myVm);
                        this.conn.invoke(
                            'GetReverseGeoCode',
                            myVm.device.serialNumber,
                            myVm.device.latitude,
                            myVm.device.longitude
                        );
                    }
                });

                this.clusterer.addMarker(newMarker);
                // todo this might need to be limited to only initial load
                bounds.extend(newMarker.position);
                deviceVM.marker = newMarker;
            }
        }

        for (const deviceVM of removedDevices) {
            if (deviceVM.marker) {
                this.clusterer.removeMarker(deviceVM.marker);
            }
        }

        this.devices_asOfLastUpdate = devices_newUpdate;

        if (this.state_hasCompletedAtLeastOneUpdateDisplay === false) {
            this.map.fitBounds(bounds);
        }
    }

    getDeviceDisplayName(device: IDeviceData) {
        if (!device.assignedUsername) {
            if (device.isBodycam) {
                return device.serialNumber + ' (No User)';
            } else {
                return device.deviceName + ' (No User)';
            }
        }

        if (device.firstName && device.lastName) {
            return `${device.firstName} ${device.lastName}`;
        }

        if (device.assignedUsername) {
            return device.assignedUsername;
        }

        return device.deviceName;
    }

    getDeviceSortName(device: IDeviceData) {
        const possibleNumericNamePartEx = RegExp(`.*?([0-9]+).*?`);
        const possibleNumericNamePartMatch = possibleNumericNamePartEx.exec(device.deviceName);
        let possibleNumericNamePart = null;
        if (possibleNumericNamePartMatch && possibleNumericNamePartMatch.length === 2 && possibleNumericNamePartMatch[1]) {
            try {
                possibleNumericNamePart = Number(possibleNumericNamePartMatch[1]);
            } catch (error) { }
        }

        let result = device.deviceName;
        if (possibleNumericNamePart) {
            result = possibleNumericNamePart;
        }
        return result;
    }

    buildBasicContent(deviceVM: IDeviceViewModel) {
        const content = document.createElement('div');
        content.id = `${deviceVM.device.serialNumber}_marker_content_root`;
        content.classList.add('live-marker');
        content.classList.add(deviceVM.device.isOnline ? 'online' : 'offline');
        content.innerHTML = `
  <div class="live-basic">
      <span class="material-icons device-type-icon">${deviceVM.device.isBodycam ? 'person' : 'local_taxi'}</span>
      <span>${this.getDeviceDisplayName(deviceVM.device)}</span>
  </div>
  `;
        deviceVM.basicContent = content;

        return content;
    }

    buildDetailsContent(deviceVM: IDeviceViewModel) {
        const content = document.createElement('div');
        content.classList.add('live-marker');
        content.classList.add(deviceVM.device.isOnline ? 'online' : 'offline');
        content.innerHTML = `
  <div class="live-basic">
      <span class="material-icons device-type-icon">${deviceVM.device.isBodycam ? 'person' : 'local_taxi'}</span>
      <span>${this.getDeviceDisplayName(deviceVM.device)}</span>
  </div>

  <div class="live-details">
      <div style="display: flex; align-items: center; padding: 0 5px;">
          <span style="flex: 1 1 auto; padding: 4px;">S/N: </span>
          <span style="padding: 4px 8px; font-size: 12px;">${deviceVM.device.serialNumber}</span>
      </div>
      <div id="${deviceVM.device.serialNumber
            }_location_container" style="display: none; align-items: center; padding: 0 5px;">
          <span style="flex: 1 1 auto; padding: 4px;">Location: </span>
          <span id="${deviceVM.device.serialNumber
            }_location_contents" style="padding: 4px 8px; font-size: 12px;">tbd</span>
      </div>
      ${deviceVM.device.isOnline === false
                ? `
              <div style="display: flex; align-items: center; padding: 0 5px;">
                  <span style="flex: 1 1 auto; padding: 4px;">Last Connected: </span>
                  <span style="padding: 4px 8px; font-size: 12px;">${this.timeAgo(deviceVM.device.lastConnected)}</span>
              </div>`
                : ``
            }
      <div style="display: flex; align-items: center; justify-content:flex-end; padding: 0 5px;">
          ${deviceVM.device.hasLiveViewPermission
                ? `<button class="live-view-button" data-deviceid="${deviceVM.device.deviceId}" disabled=true>
              <div class="material-icons map-marker-button-icon">videocam</div>
              <span>View Live</span>
          </button>`
                : ``
            }
          <button class="request-video-button" style="margin-left: 10px;">
              <div class="material-icons map-marker-button-icon">video_file</div>
              <span>Request Video</span>
          </button>
      </div>
  </div>
  `;

        const liveButton = content.getElementsByClassName('live-view-button');
        if (liveButton.length > 0) {
            liveButton[0].addEventListener('click', (event: MouseEvent) => {
                this.mapMarkerOpenDeviceLiveView(event, deviceVM.device.deviceId);
            });
        }

        const requestVideoButton = content.getElementsByClassName('request-video-button');
        if (requestVideoButton.length > 0) {
            requestVideoButton[0].addEventListener('click', (event: MouseEvent) => {
                this.mapMarkerOpenDeviceRequestVideo(event, deviceVM.device.deviceId);
            });
        }

        deviceVM.detailsContent = content;
        return content;
    }

    liveViewDisabled(deviceId: string) {
        let deviceVM = this.devices_asOfLastUpdate.find((x) => x.device.deviceId === deviceId);
        return !deviceVM || !deviceVM.device.cameras || deviceVM.device.cameras.length === 0;
    }

    mapMarkerOpenDeviceLiveView(event: MouseEvent | null, deviceId: string) {
        let deviceVM = this.devices_asOfLastUpdate.find((x) => x.device.deviceId === deviceId);
        this.popupCameraSelectForLiveView(event, deviceVM);
    }

    mapMarkerOpenDeviceRequestVideo(event: MouseEvent | null, deviceId: string) {
        let deviceVM = this.devices_asOfLastUpdate.find((x) => x.device.deviceId === deviceId);
        this.handleRequestVideo(event, deviceVM);
    }

    updateContent(deviceVM: IDeviceViewModel) {
        if (!deviceVM.marker) {
            return;
        }

        if (deviceVM.isCollapsed) {
            deviceVM.marker.content = deviceVM.basicContent;
        } else {
            this.devices_asOfLastUpdate
                .filter((x) => x.device.serialNumber !== deviceVM.device.serialNumber)
                .forEach((x) => {
                    if (x.marker) {
                        x.marker.zIndex = null;
                    }
                });
            deviceVM.marker.zIndex = 1;
            this.updateMarkerContent(deviceVM);
        }
    }

    updateMarkerContent(deviceVM: IDeviceViewModel) {
        const viewLiveButton: HTMLButtonElement = deviceVM.detailsContent.querySelector('.live-view-button');
        if (viewLiveButton) {
            // We changed how this works - if the user doesn't have view live permission when the details marker is created disabling it won't work since the button doesn't exist.
            // todo revisit/cleanup
            viewLiveButton.disabled = this.liveViewDisabled(deviceVM.device.deviceId);
        }

        const targetElementContainer: HTMLDivElement = deviceVM.detailsContent.querySelector(
            `[id='${deviceVM.device.serialNumber}_location_container']`
        );
        if (deviceVM.location) {
            const targetElement: HTMLDivElement = deviceVM.detailsContent.querySelector(
                `[id='${deviceVM.device.serialNumber}_location_contents']`
            );
            targetElementContainer.style.display = 'flex';
            targetElement.textContent = deviceVM.location;
        } else {
            targetElementContainer.style.display = 'none';
        }
        deviceVM.marker.content = deviceVM.detailsContent;
    }

    timeAgo(input) {
        if (input === null || input === undefined) {
            return 'never';
        }
        const date = input instanceof Date ? input : new Date(input);
        const formatter = new Intl.RelativeTimeFormat('en');
        const ranges = [
            ['years', 3600 * 24 * 365],
            ['months', 3600 * 24 * 30],
            ['weeks', 3600 * 24 * 7],
            ['days', 3600 * 24],
            ['hours', 3600],
            ['minutes', 60],
            ['seconds', 1],
        ] as const;

        const secondsElapsed = (date.getTime() - Date.now()) / 1000;

        for (const [rangeType, rangeVal] of ranges) {
            if (rangeVal < Math.abs(secondsElapsed)) {
                const delta = secondsElapsed / rangeVal;
                return formatter.format(Math.round(delta), rangeType);
            }
        }
    }

    launchLiveView(deviceId: string, cameras?: IDeviceCameraViewModel[]) {
        this.dialog.open(LiveDownloadDialogComponent, {});

        let cameraFragment = "";

        if (cameras && cameras.length > 0) {
            const quality = cameras.length === 1 ? 'HD' : 'VGA';
            for (const camera of cameras) {
                cameraFragment += `/${camera.index}${quality}`;
            }
        }

        this.devicesService.apiApiDevicesDeviceLiveViewLinkGet(deviceId)
            .toPromise()
            .then((res) => {
                let productUrl = this.isStaging() ? "pvmmstaging" : "securamax"
                let url =
                    'pv-live://' +
                    res.url +
                    productUrl +
                    '/' +
                    res.authToken +
                    '/' +
                    res.liveViewUID +
                    cameraFragment;
                window.location.href = url
            });
    }

    isStaging() {
        return window.location.href.includes("pvmmstaging");
    }

    ngOnInit() {
        // from(this.conn.start()).subscribe();
        //this.debugState.useTestingDevices = true;
        //this.debugState.sh/cwOtherDevices = true;
        //this.debugCreateGui();
    }

    ngOnDestroy() {
        for (const [event, listener] of Object.entries(this.inactivityEventHandlers)) {
            (<any>window).removeEventListener(event, listener);
        }
        clearInterval(this.mainTimerId);
        clearInterval(this.autoRefreshTimerId);
        this.conn.stop();
        if (this.debugGui) {
            this.debugGui.destroy();
        }
    }

    ngAfterViewInit() {
        this.newState.hasViewInitCompleted = true;

        this.setDeviceListHeight();
    }

    @debounce(100)
    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        this.setDeviceListHeight();
    }

    setDeviceListHeight() {
        const liveViewContainer = document.querySelector('.live-view-container') as HTMLElement | undefined;
        const searchToolbar = document.querySelector('.searchToolbar') as HTMLElement | undefined;
        const deviceList = document.querySelector('.device-list') as HTMLElement | undefined;

        const bottomOfToolbar = searchToolbar.getBoundingClientRect().bottom;
        const bottomOfContainer = liveViewContainer.getBoundingClientRect().bottom;

        let diff = 0;
        // if map element is off the bottom of the viewport
        if (bottomOfContainer > window.innerHeight) {
            diff = bottomOfContainer - window.innerHeight;
        }

        let height = bottomOfContainer - bottomOfToolbar - 14 - diff;
        deviceList.style.maxHeight = `${height}px`;
    }

    debugCreateGui() {
        this.debugGui = new GUI({ title: 'Debug' });
        this.debugGui.add(this.debugState, 'pauseUpdates').name('Disable update processing');
        this.debugGui.add(this.debugState, 'showTestPopups').name('Show test popups');
        this.debugGui.add(this.debugState, 'fakeInactivity').name('Fake inactive');

        const testingDevicesFolder = this.debugGui.addFolder('Testing Devices');
        testingDevicesFolder.close();
        testingDevicesFolder.add(this.debugState, 'useTestingDevices');
        testingDevicesFolder.add(this.debugState, 'testPositionLat', -55, 55, 0.01);
        testingDevicesFolder.add(this.debugState, 'testPositionLong', -55, 55, 0.01);
        testingDevicesFolder.add(this.debugState, 'simulateGpsLoss');
        testingDevicesFolder.add(this.debugState, 'showOtherDevices');
        testingDevicesFolder.add(this.debugState, 'devicesToAddToList', 0);
        testingDevicesFolder.add(this.debugState, 'hideAllDevices');
    }

    popupCameraSelectForLiveView(event: any, deviceVM: IDeviceViewModel) {
        event.stopPropagation();
        event.preventDefault();
        if (deviceVM.device.deviceTypeName === "BC4") {
            this.launchLiveView(deviceVM.device.deviceId);
        } else {
            const dialogRef = this.dialog.open(LiveCameraSelectDialogComponent, {
                data: {
                    deviceVM,
                },
            });

            dialogRef.afterClosed().subscribe((selectedCameras) => {
                this.launchLiveView(deviceVM.device.deviceId, selectedCameras);
            });
        }
    }
            
    onSeachOrFilterChangeCallback() {
        window.dispatchEvent(new CustomEvent('search or filter change'));
    }

    openInactivityDialog() {
        const dialogRef = this.dialog.open(LiveInactivityDialogComponent, {});
        dialogRef.afterClosed().subscribe(() => {
            this.newState.inactivityMessage_closedByUserAt = new Date();
        });
    }

    openFilterDialog() {
        const dialogRef = this.dialog.open(LiveFilterDialog, {
            data: {
                sortField: this.filterSettings.sortField,
                sortDirection: this.filterSettings.sortDirection,
                addressDisplay: this.filterSettings.addressDisplay,
                filter: {
                    showOffline: this.filterSettings.filter.showOffline,
                    showBodycams: this.filterSettings.filter.showBodycams,
                    showVehicles: this.filterSettings.filter.showVehicles,
                },
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.filterSettings.sortField = result.sortField;
                this.filterSettings.sortDirection = result.sortDirection;
                this.filterSettings.addressDisplay = result.addressDisplay;
                this.filterSettings.filter.showOffline = result.filter.showOffline;
                this.filterSettings.filter.showBodycams = result.filter.showBodycams;
                this.filterSettings.filter.showVehicles = result.filter.showVehicles;

                // todo move filter settings to the event parameters
                window.dispatchEvent(new CustomEvent('search or filter change'));
            }
        });
    }

    resetZoom() {
        const bounds = new google.maps.LatLngBounds();

        this.devices_asOfLastUpdate.forEach((deviceVM: IDeviceViewModel) => {
            bounds.extend(new google.maps.LatLng(deviceVM.device.latitude, deviceVM.device.longitude));
        });

        this.map.fitBounds(bounds);
    }

    zoomToDevice(event: any, device: IDeviceData) {
        event.stopPropagation();
        const targetLatLong = new google.maps.LatLng(device.latitude, device.longitude);
        const zoom = 18;
        this.map.setCenter(targetLatLong);
        this.map.setZoom(zoom);
        // const bounds = new google.maps.LatLngBounds();
        // bounds.extend(targetLatLong);
        // this.map.fitBounds(bounds);
    }

    createDeviceVMForDevice(device: IDeviceData) {
        const result: IDeviceViewModel = {
            device,
            marker: null,
            markerElement: null,
            isCollapsed: true,
            location: null,
            basicContent: null,
            detailsContent: null,
        };

        // Technically, this doesn't handle missing just lat or just long, but that's too unlikely right now.
        if (
            (!result.device.latitude && !result.device.longitude) ||
            (result.device.latitude &&
                result.device.latitude === 0 &&
                result.device.longitude &&
                result.device.longitude === 0)
        ) {
            result.device.latitude = this.defaultLocationLat;
            result.device.longitude = this.defaultLocationLong;
        }

        return result;
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.shiftKey && event.key === 'F1') {
            event.preventDefault();
            if (this.debugGui) {
                if (this.debugGui._hidden) {
                    this.debugGui.show();
                } else {
                    this.debugGui.hide();
                }
            } else {
                this.debugCreateGui();
            }
        }
    }

    async loadMapsApi() {
        const loader = new Loader({
            apiKey: 'AIzaSyBHEXlzkGnd2Q-Hw_Bb9MT0PTZzikt4Ft4',
            version: 'weekly',
        });
        await loader.load();
        const { Map } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;
        await google.maps.importLibrary('marker');
        const { LatLng } = (await google.maps.importLibrary('core')) as google.maps.CoreLibrary;
        const center = new LatLng(37.43238031167444, -122.16795397128632);
        const mainMapElem = document.getElementById('main-map');
        this.map = new Map(mainMapElem, {
            center,
            zoom: 11,
            mapId: '466f1723671cde5a',
            maxZoom: 35,
            gestureHandling: 'greedy',
        });

        this.clusterer = new MarkerClusterer({ map: this.map });
    }

    handlePanelClick(deviceVM: IDeviceViewModel) {
        this.devices_asOfLastUpdate
            .filter((x) => x.device.serialNumber !== deviceVM.device.serialNumber)
            .forEach((x) => {
                x.isCollapsed = true;
                this.updateContent(x);
            });
        deviceVM.isCollapsed = !deviceVM.isCollapsed;
        this.updateContent(deviceVM);

        this.conn.invoke(
            'GetReverseGeoCode',
            deviceVM.device.serialNumber,
            deviceVM.device.latitude,
            deviceVM.device.longitude
        );
    }

    panelTrackBy(index: number, item: IDeviceViewModel) {
        return item.device.serialNumber;
    }

    handleRequestVideo(event: MouseEvent | null, device: IDeviceViewModel) {
        // called by the sidebar panels
        if (event) {
            event.stopPropagation();
        }
        const dialogRef = this.dialog.open(CreateRequestDialogComponent, {
            data: {
                id: device.device.deviceId,
                deviceName: device.device.deviceName,
                deviceSerial: device.device.serialNumber,
            },
        });
        dialogRef.afterClosed().subscribe(() => { });
    }
}
