import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { DeletionRequestViewModel, DocumentPermissionsViewModel, DocumentTaskService } from 'src/app/services/api2';
import { FormControl, FormGroup } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { reviewDeleteRequestDialogResult } from '../document-details2.component'; 

@Component({
  selector: 'app-review-delete-request-dialog',
  standalone: true,
    imports: [
        ReactiveFormsModule,
        MatDialogModule,
        MatInputModule,
        MatFormFieldModule
    ],
  templateUrl: './review-delete-request-dialog.component.html',
  styleUrl: './review-delete-request-dialog.component.css'
})
export class ReviewDeleteRequestDialogComponent {
    isSubmittingDeletionRequest: boolean;

    form = new FormGroup({
        deleteRequestReason: new FormControl('')
    });

    constructor(
        private dialogRef: MatDialogRef<ReviewDeleteRequestDialogComponent>,
        private documentTaskService: DocumentTaskService,
        private toastr: SnackbarService,
        @Inject(MAT_DIALOG_DATA) public data: {deletionRequest: DeletionRequestViewModel, permissions: DocumentPermissionsViewModel}
    ) { }

    ngOnInit(): void {
    }

    onCancel() {
        this.dialogRef.close(false);
    }

    approveDeleteRequest() {
        
    }

    denyDeleteRequest() {
        if (this.data.permissions?.canApproveDelete) {
            this.documentTaskService.apiApiDocumentTaskApproveRequestDeletePost(
                this.data.deletionRequest.taskId, false
            ).subscribe(x => {
                this.dialogRef.close({isPending: false, removeDeletionRequest: true})
            }, (err) => {
                this.toastr.error("An error occurred while approving deletion.");
            });
        } else {
            this.toastr.error("You don't have authorization to deny this deletion request.");
        }
        
    }

    // onSubmit() {
    //     this.isSubmittingDeletionRequest = true;

    //     this.documentTaskService.apiApiDocumentTaskRequestDeletePost(this.documentId, this.form.value.deleteRequestReason).subscribe((data) => {
    //         this.toastr.success('Deletion request successfully submitted.')
    //         this.isSubmittingDeletionRequest = false;
    //         this.dialogRef.close(true);
    //     },
    //         (err) => {
    //             this.toastr.error('An error occured while requesting deletion.')
    //             this.isSubmittingDeletionRequest = false;
    //         });
    // }
}
