import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AnnotateService } from '../../../services/annotate.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
    IReviewMetadataControlsComponentForm,
    ReviewMetadataControls2Component,
} from '../../review/review-metadata-controls2/review-metadata-controls2.component';
import { SnackbarService } from '../../../services/snackbar.service';

import {
    DocumentMetadataStatusViewModel,
    DocumentMetadataViewModel,
    MetadataDayViewModel,
    MetadataService,
    TagViewModel,
    TagsService,
    TimeBasedTagsAutoTaggedTagsViewModel,
} from 'src/app/services/api2';

interface IAnnotate2Step2ComponentForm {
    metadata: FormGroup<IReviewMetadataControlsComponentForm>;
    files: FormControl<DocumentMetadataStatusViewModel[] | null>;
}

@Component({
    selector: 'app-annotate2-step2',
    templateUrl: './annotate2-step2.component.html',
    styleUrls: ['./annotate2-step2.component.css'],
})
export class Annotate2Step2Component {
    metadataPendingDay: MetadataDayViewModel;
    allTags: TagViewModel[] = [];
    isSubmittingMetaData: boolean = false;

    form: FormGroup<IAnnotate2Step2ComponentForm>;

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private metadataService: MetadataService,
        private toastr: SnackbarService,
        private annotateService: AnnotateService,
        private tagsService: TagsService
    ) {
        this.form = fb.group({
            metadata: ReviewMetadataControls2Component.buildFormPart(fb),
            files: [[] as DocumentMetadataStatusViewModel[]],
        });
    }

    ngAfterContentInit() {
        const x = this.annotateService.currentAnnotation.getValue();
        if (!x.metadataPendingDay) {
            this.router.navigate(['/annotate2/step1']).then(() => {});
        }
        x.step = 2;
        this.annotateService.currentAnnotation.next(x);
        console.log(this.annotateService.currentAnnotation.getValue());

        this.tagsService.apiApiTagsGetAllActiveTagsV2Get().subscribe(
            (tags) => {
                this.allTags = tags.filter((x) => x.tagTypeId === 1);
            },
            () => {
                this.toastr.error('Error occurred while getting tags.');
            }
        );

        this.metadataPendingDay = x.metadataPendingDay;
    }

    selectAllClicked() {
        this.form.controls.files.setValue(this.metadataPendingDay.files);
    }

    deselectAllClicked() {
        this.form.controls.files.setValue([]);
    }

    reviewSelectedClicked() {
        let canSave = true;

        this.form.markAllAsTouched();

        if (!this.form.valid) {
            this.toastr.error('There are issues that need addressing.');
            canSave = false;
        }

        if (
            !this.form.controls.files.value ||
            this.form.controls.files.value.length === 0
        ) {
            this.toastr.error('At least one file must be selected.');
            canSave = false;
        }

        if (canSave) {
            this.isSubmittingMetaData = true;
            const documentMetadatas: DocumentMetadataViewModel[] = [];
            const selectedFiles = this.form.controls.files.value;
            for (const file of selectedFiles) {
                const documentMetadata: DocumentMetadataViewModel = {};
                documentMetadata.documentId = file.id;
                documentMetadata.autoCategorized = file.autoCategorized;
                documentMetadata.cases =
                    this.form.controls.metadata.controls.casesForm.value;
                documentMetadata.categories =
                    this.form.controls.metadata.controls.categoriesForm.value;
                documentMetadata.description =
                    this.form.controls.metadata.controls.description.value;
                documentMetadata.title =
                    this.form.controls.metadata.controls.title.value;
                documentMetadata.tags = [];
                for (const rawTag of this.form.controls.metadata.controls.tags
                    .controls) {
                    const tag = rawTag as any;
                    const matchingTag = this.allTags.filter(
                        (x) => x.name === tag.name
                    )[0];
                    const x: TimeBasedTagsAutoTaggedTagsViewModel = {};
                    x.tagId = matchingTag.id;
                    if (Array.isArray(tag.value)) {
                        x.value = '';
                    } else {
                        x.value = tag.value;
                    }
                    documentMetadata.tags.push(x);
                }
                documentMetadatas.push(documentMetadata);
            }

            this.metadataService.apiApiMetadataPut(documentMetadatas).subscribe(
                () => {
                    this.toastr.success('Saved.');
                    this.isSubmittingMetaData = false;
                    this.router.navigate(['/annotate2/step1']).then(() => {});
                },
                () => {
                    this.isSubmittingMetaData = false;

                    this.toastr.error(
                        'An error occurred while saving the annotation.'
                    );
                }
            );
        }
    }
}
