<div class="card mt-3">
    <div class="document-details-body card-body" *ngIf="document">
        <!-- <h2 class="card-title" style="grid-column: 1/-1" >
            Document Details
        </h2> -->

        <div class="title">
            <h1>
                Uploaded on
                {{ document.uploaded | date : "MMM d YYYY, h:mm a" }}
                owned by
                {{ document.ownerName }}
            </h1>
        </div>

        <div class="video-container">
            <div style="height: 100%"
                 *ngIf="
                 (document.versions.length>
                0 ||
                document.parentId != null) &&
                document.isDeleted === false &&
                document.mimeType.substring(0, 5) == 'video'
                "
                >
                <vg-player *ngIf="videoSrcUrl">
                    <video [vgMedia]="media"
                           #media
                           id="singleVideo"
                           preload="auto"
                           controls>
                        <source [src]="videoSrcUrl" type="video/mp4" />
                    </video>
                </vg-player>

                <!-- <div class="base-container" style="margin-top: 10px"> -->
                <!--<div class="dropscroll-local-0">-->
                <!--<div class="dropscroll-local-1">-->
                <!--<div class="content-container">-->
                <!-- <mat-card> -->
                <!-- <mat-card-content> -->
                <!-- <div -->
                <!-- class="document-item keyvalue-parent" -->
                <!-- > -->
                <!-- <label -->
                <!-- *ngIf="!document.parentId" -->
                <!-- class="details-label" -->
                <!-- style="margin-left: 0" -->
                <!-- > -->
                <!-- Title -->
                <!-- </label> -->
                <!-- <label -->
                <!-- *ngIf=" -->
                <!-- document.parentId != null -->
                <!-- " -->
                <!-- class="details-label" -->
                <!-- style="font-size: 11px" -->
                <!-- > -->
                <!-- Title&nbsp;Of&nbsp;Original -->
                <!-- </label> -->
                <!-- <div> -->
                <!-- <span *ngIf="!document.title" -->
                <!-- >Not Set</span -->
                <!-- > -->
                <!-- <span *ngIf="document.title">{{ -->
                    <!-- document.title -->
                <!-- }}</span> -->
                <!-- </div> -->
                <!-- <label class="details-label" -->
                <!-- >Description</label -->
                <!-- > -->
                <!-- <span *ngIf="!document.description" -->
                <!-- >Not Set</span -->
                <!-- > -->
                <!-- <span *ngIf="document.description"> -->
                <!-- {{ document.description }} -->
                <!-- </span> -->
                <!-- <label class="details-label" -->
                <!-- >Owner</label -->
                <!-- > -->
                <!-- <div> -->
                <!-- {{ document.ownerName }} -->
                <!-- </div> -->
                <!-- <label class="details-label" -->
                <!-- >Projected Deletion Date</label -->
                <!-- > -->
                <!-- <div> -->
                <!-- {{ -->
                    <!-- document.deletionDate.toString() -->
                        <!-- | timeAgo : true -->
                <!-- }} -->
                <!-- </div> -->
                <!-- </div> -->
                <!-- <br /> -->
                <button class="smx-secondary-button additional-details-button"
                        (click)="openAdditionalDetailsDialog()">
                    Additional Details
                </button>
                <!-- </mat-card-content> -->
                <!-- </mat-card> -->
                <!-- </div> -->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
            </div>
        </div>

        <div class="sidebar-container">
            <div *ngIf="document.permissions.canShare === true"
                 class="label-and-control-container">
                <button class="smx-secondary-button w-100"
                        (click)="openCreateShareDialog()"
                        [disabled]="!document.permissions.canShare">
                    Create Share
                    <fa-icon icon="share"></fa-icon>
                </button>
            </div>

            <div *ngIf="
                    document.mimeType.substring(0, 5) == 'video' && isWindows
                "
                 class="label-and-control-container">
                <button class="smx-secondary-button w-100"
                        [disabled]="
                        !document.permissions.canDownload ||
                        document.statusId === documentStatus.archived
                    "
                        (click)="streamDocument()"
                        [attr.title]="
                        document.statusId === documentStatus.archived
                            ? 'You cannot watch an archived video. You must first request retrieval.'
                            : null
                    ">
                    Watch in HD
                    <fa-icon [icon]="['fab', 'youtube']"></fa-icon>
                </button>
                <span *ngIf="this.showViewerLink">
                    HD Playback requires the HD Viewer app to be installed.
                    <a href="https://pvsupport.blob.core.windows.net/software/HDViewer/PV.HDViewerSetup.exe">Get the App</a>
                </span>
            </div>

            <div class="label-and-control-container">
                <button class="smx-secondary-button w-100"
                        [disabled]="
                        !document.permissions.canDownload ||
                        document.statusId === documentStatus.archived
                    "
                        (click)="downloadDocument()"
                        [attr.title]="
                        document.statusId === documentStatus.archived
                            ? 'You cannot download an archived video. You must first request retrieval.'
                            : null
                    ">
                    Download Video

                    <fa-icon icon="download"></fa-icon>
                </button>
            </div>

            <div class="label-and-control-container"
                 *ngIf="document.parentId == null">
                <button class="smx-secondary-button w-100"
                        (click)="openModifyMetadataDialog()"
                        [disabled]="!document.permissions.canEditMetadata">
                    Edit Metadata&nbsp;<fa-icon icon="edit"></fa-icon>
                </button>
            </div>

            <div class="label-and-control-container">
                <button class="smx-secondary-button w-100"
                        style="font-size: 0.8em; min-height: 44px"
                        [disabled]="!document.permissions.canDownloadAuditReport"
                        (click)="downloadAuditReport()">
                    Download Audit Report
                    <fa-icon icon="key"></fa-icon>
                </button>
            </div>

            <div class="label-and-control-container"
                 *ngIf="
                 document.versions.length>
                0 &&
                document.isDeleted === false &&
                document.mimeType.substring(0, 5) == 'video'
                "
                >
                <button class="smx-secondary-button w-100"
                        routerLink="/documents/{{ document.id }}/redactions/create"
                        [disabled]="document.statusId === documentStatus.archived">
                    Create Redaction
                    <mat-icon style="vertical-align: middle">domain_add</mat-icon>
                </button>
            </div>
            <div class="label-and-control-container"
                 *ngIf="
                 document.versions.length>
                0 &&
                document.isDeleted === false &&
                document.mimeType.substring(0, 5) == 'video'
                "
                >
                <button class="smx-secondary-button w-100"
                        style="font-size: 0.75em; min-height: 44px"
                        routerLink="/documents/{{
                        document.id
                    }}/redactions/advanced"
                        [disabled]="
                        document.statusId === documentStatus.archived ||
                        !document.permissions.canAdvancedRedact
                    ">
                    Create Advanced Redaction
                    <mat-icon style="vertical-align: middle">domain_disabled</mat-icon>
                </button>
            </div>

            <div class="label-and-control-container"
                 *ngIf="
                    isUnlimitedPlanUser &&
                    document.statusId === documentStatus.archived &&
                    document.permissions.canView
                ">
                <button class="smx-secondary-button w-100"
                        style="font-size: 0.8em; min-height: 44px">
                    <span style="vertical-align: middle">
                        Start Archive Retrieval
                    </span>
                    <mat-icon style="vertical-align: top">drive_folder_upload</mat-icon>
                </button>
            </div>

            <div>
                <button mat-button
                        *ngIf="!document.isDeletionLocked && !isRequestDelete && !isReviewDeletionRequest"
                        class="smx-primary-button mb-2 mr-2" (click)="lockFromDeletion()"
                        [disabled]="!document.permissions.canDeletionLock" i18n-title title="Lock"
                        i18n>
                    <fa-icon [icon]="'unlock'"></fa-icon>
                    Lock from Deletion
                </button>
                <button mat-button
                        *ngIf="document.isDeletionLocked && !isRequestDelete && !isReviewDeletionRequest"
                        class="smx-primary-button mb-2 mr-2" (click)="unlockFromDeletion()"
                        [disabled]="!document.permissions.canDeletionLock" i18n-title title="Unlock"
                        i18n>
                    <fa-icon [icon]="['fas', 'lock']"></fa-icon>
                    Unlock from Deletion
                </button>

                <button mat-button
                        *ngIf="!isRequestDelete && !document.isDeletionLocked && !document.deletionRequest"
                        class="smx-critical-button mb-2 mr-2"
                        (click)="openRequestDeleteDialog()"
                        [disabled]="!document.permissions.canRequestDelete || document.isDeletionLocked || requestDeleteSubmitClicked"
                        i18n-title title="Request Deletion" i18n>
                    Request Deletion
                </button>

                <button mat-button class="smx-primary-button mb-2"
                        [disabled]="!document.permissions.canApproveDelete || document.isDeletionLocked"
                        *ngIf="!isReviewDeletionRequest && !document.isDeletionLocked && document.deletionRequest"
                        (click)="openReviewDeleteRequestDialog()" i18n>
                    Review Deletion Request
                </button>

            </div>
        </div>
    </div>
</div>
